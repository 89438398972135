/* eslint-disable class-methods-use-this */
/* eslint-disable no-constructor-return */
import { combineLatest, Observable, of } from 'rxjs';
import { map, share, switchMap } from 'rxjs/operators';
import * as R from 'ramda';

import {
  asNetworkSlug,
  type NetworkSlug,
  type Price,
  type PriceHistory,
  type PriceProvider,
  type ProductSlug,
  type VersionSlug,
} from 'domain/types';
import { getOracleBaseAddresses } from 'domain/utils';
import { memoize } from 'utils/decorators';
import { CIMap } from 'utils/js';
import { CollectionRequestCache } from 'api/utils/CollectionRequestCache';
import { resetOnZeroRefs } from 'utils/rxjs';
import { LONG_POLLING_TIMEOUT } from 'env';

import { apiErrorInterceptor } from './apiErrorInterceptor';
import {
  convertMarketPriceHistory,
  convertMarketPrices,
  convertOraclePriceHistory,
  convertOraclePriceProviders,
  convertOraclePrices,
} from './converters';
import {
  loadMarketPrice,
  loadMarketPriceHistory,
  loadOraclePrice,
  loadOraclePriceHistory,
  loadOraclePriceProviders,
} from './apostro-rest/public';

class PriceApi {
  constructor() {
    return apiErrorInterceptor.getProxiedObj(this);
  }

  @memoize((...args: NetworkSlug[]) => R.toString(args))
  private getMarketPricesCache(network: NetworkSlug) {
    return new CollectionRequestCache({
      fetchData$: addresses => loadMarketPrice({ addresses, network_slug: network }),
      refreshTimeout: LONG_POLLING_TIMEOUT,
    });
  }

  @memoize((...args: string[]) => R.toString(args))
  private getOraclePricesCache({
    productSlug,
    versionSlug,
    network,
  }: {
    productSlug: ProductSlug;
    versionSlug: VersionSlug;
    network: NetworkSlug;
  }) {
    return new CollectionRequestCache({
      fetchData$: (assets: string[]) =>
        loadOraclePrice({
          assets,
          params: {
            product_slug: productSlug,
            version_slug: versionSlug,
            network_slug: network,
          },
        }),
      refreshTimeout: LONG_POLLING_TIMEOUT / 2,
    });
  }

  @memoize((...args: string[]) => R.toString(args))
  public getMarketPrices$({
    assets,
    network,
  }: {
    assets: string[];
    network: NetworkSlug;
  }): Observable<CIMap<string, Price | null>> {
    return this.getMarketPricesCache(network)
      .get$(assets)
      .pipe(
        map(prices => convertMarketPrices(assets, prices)),
        share(resetOnZeroRefs()),
      );
  }

  @memoize((...args: string[]) => R.toString(args))
  public getOraclePrices$({
    productSlug,
    versionSlug,
    network,
    assets,
  }: {
    productSlug: ProductSlug;
    versionSlug: VersionSlug;
    network: NetworkSlug;
    assets: string[];
  }): Observable<CIMap<string, Price | null>> {
    return this.getOraclePricesCache({ productSlug, versionSlug, network })
      .get$(assets)
      .pipe(
        switchMap(result => {
          // addresses in Ethereum mainnet
          const oracleBaseAddresses = getOracleBaseAddresses(result);
          return combineLatest([
            of(result),
            this.getMarketPrices$({
              assets: oracleBaseAddresses,
              network: asNetworkSlug('mainnet'),
            }),
          ]);
        }),
        map(([data, oracleBasePrices]) =>
          convertOraclePrices({
            assets,
            data,
            oracleBasePrices,
            expiringTimestamp: Date.now() - 15 * 60 * 1000,
          }),
        ),
        share(resetOnZeroRefs()),
      );
  }

  @memoize((...args: string[]) => R.toString(args))
  public getOraclePriceHistory$({
    productSlug,
    versionSlug,
    network,
    tokenAddress,
    from,
    to,
    timeInterval,
  }: {
    productSlug: ProductSlug;
    versionSlug: VersionSlug;
    network: NetworkSlug;
    tokenAddress: string;
    from: Date;
    to: Date;
    timeInterval: number;
  }): Observable<PriceHistory> {
    const start = from.getTime();
    const end = to.getTime();
    return loadOraclePriceHistory({
      product_slug: productSlug,
      version_slug: versionSlug,
      network_slug: network,
      token_address: tokenAddress,
      from_timestamp: start,
      to_timestamp: end,
    }).pipe(
      switchMap(result => {
        // addresses in Ethereum mainnet
        const oracleBaseAddresses = getOracleBaseAddresses(
          result.points.map(([, , base]) => ({ base })),
        );
        return combineLatest([
          of(result),
          of(oracleBaseAddresses),
          oracleBaseAddresses.length
            ? combineLatest(
                oracleBaseAddresses.map(baseAddress =>
                  this.getMarketPriceHistory$({
                    tokenAddress: baseAddress,
                    network: asNetworkSlug('mainnet'),
                    from,
                    to,
                  }),
                ),
              )
            : of([]),
        ]);
      }),
      map(([result, baseAddresses, oracleBasePricesHistory]) =>
        convertOraclePriceHistory(
          result,
          new CIMap(oracleBasePricesHistory.map((x, i) => [baseAddresses[i], x])),
          from,
          to,
          timeInterval,
        ),
      ),
      share(resetOnZeroRefs()),
    );
  }

  @memoize((...args: string[]) => R.toString(args))
  public getMarketPriceHistory$({
    tokenAddress,
    network,
    from,
    to,
  }: {
    tokenAddress: string;
    network: NetworkSlug;
    from: Date;
    to: Date;
  }): Observable<PriceHistory> {
    return loadMarketPriceHistory({
      network_slug: network,
      token_address: tokenAddress,
      start_unix_time: Math.floor(from.getTime() / 1000),
      end_unix_time: Math.floor(to.getTime() / 1000),
    }).pipe(map(convertMarketPriceHistory), share(resetOnZeroRefs()));
  }

  @memoize((...args: string[]) => R.toString(args))
  public getOraclePriceProviders$({
    productSlug,
    versionSlug,
    network,
    assets,
  }: {
    productSlug: ProductSlug;
    versionSlug: VersionSlug;
    network: NetworkSlug;
    assets: string[];
  }): Observable<Array<PriceProvider | null>> {
    return assets.length
      ? loadOraclePriceProviders({
          assets,
          params: {
            product_slug: productSlug,
            version_slug: versionSlug,
            network_slug: network,
          },
        }).pipe(map(convertOraclePriceProviders))
      : of([]);
  }

  @memoize((...args: string[]) => R.toString(args))
  public getTokenPrices$({
    productSlug,
    versionSlug,
    network,
    assets,
  }: {
    productSlug: ProductSlug;
    versionSlug: VersionSlug;
    network: NetworkSlug;
    assets: string[];
  }): Observable<CIMap<string, Price | null>> {
    return this.getMarketPrices$({ assets, network }).pipe(
      switchMap(marketPrices => {
        const unknownAssets = assets.filter(a => !marketPrices.get(a));
        if (unknownAssets.length > 0) {
          console.warn(
            `ProtocolsApi: unknown market price for the following assets in ${productSlug}:\n${unknownAssets.join(
              ',\n',
            )}`,
          );
        }

        return combineLatest([
          of(marketPrices),
          this.getOraclePrices$({
            productSlug,
            versionSlug,
            network,
            assets: unknownAssets,
          }),
        ]);
      }),
      map(
        ([marketPrices, oraclePrices]) =>
          new CIMap(assets.map(a => [a, marketPrices.get(a) || oraclePrices.get(a) || null])),
      ),
    );
  }
}

export const priceApi = new PriceApi();
