import { O } from 'ts-toolbelt';

import { Handler } from 'api/utils/RestSDK';

import { Parameters, JsonResponses, OraclePriceHistoryResponse } from '../types';
import { BASE_URL, headers } from '../constants';

type URL = '/api/v1/oracle/prices/history';

export const loadOraclePriceHistory = Handler.new<Parameters<URL>>()
  .fetch(
    ({ product_slug, version_slug, token_address, network_slug, from_timestamp, to_timestamp }) =>
      `${BASE_URL}/v1/oracle/prices/history?${[
        `product_slug=${product_slug}`,
        `version_slug=${version_slug}`,
        `network_slug=${network_slug}`,
        `token_address=${token_address}`,
        `from_timestamp=${Math.floor(from_timestamp / 1000)}`,
        `to_timestamp=${Math.floor(to_timestamp / 1000)}`,
      ]
        .filter(Boolean)
        .join('&')}`,
    () => ({ headers }),
  )
  .parseJson<O.Merge<{ 200: OraclePriceHistoryResponse }, JsonResponses<URL, 'get'>>>({
    404: () => ({ points: [] }),
  })
  .toFn();
