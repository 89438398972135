import * as R from 'ramda';

import { Handler } from 'api/utils/RestSDK';
import { LONG_POLLING_TIMEOUT } from 'env';

import { Parameters, JsonResponses } from '../types';
import { BASE_URL, headers } from '../constants';

type URL = '/api/v1/lendings/{product_slug}/{version_slug}/{network_slug}/safe-tokens';

export const loadProtocolSafeTokens = Handler.new<{
  params: Parameters<URL>;
}>()
  .fetch(
    ({ params: { network_slug, product_slug, version_slug } }) =>
      `${BASE_URL}/v1/lendings/${product_slug}/${version_slug}/${network_slug}/safe-tokens`,
    () => ({ headers }),
  )
  .parseJson<JsonResponses<URL, 'get'>>()
  .refetchByTimeout(LONG_POLLING_TIMEOUT)
  .memoizeWith((...args) => R.toString(args))
  .toFn();
