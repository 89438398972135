import { O } from 'ts-toolbelt';

import { Handler } from 'api/utils/RestSDK';

import { RequestBody, Parameters, JsonResponses, OraclePricesResponse } from '../types';
import { BASE_URL, headers } from '../constants';

type URL = '/api/v1/oracle/prices';

export const loadOraclePrice = Handler.new<{
  assets: RequestBody<URL>;
  params: Parameters<URL>;
}>()
  .fetch(
    ({ params }) => `${BASE_URL}/v1/oracle/prices?${new URLSearchParams(params).toString()}`,
    ({ assets }) => ({
      headers,
      method: 'POST',
      body: JSON.stringify(assets),
    }),
  )
  .parseJson<O.Merge<{ 200: OraclePricesResponse }, JsonResponses<URL, 'post'>>>({
    404: ({ assets }) => assets.map(() => null),
  })
  .toFn();
