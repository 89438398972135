import { LiquidityAmount } from '@akropolis-web/primitives';

import { sumAmounts } from 'utils/amount';
import { combine, Either, MaybeEither, toEither } from 'utils/either';

import { getAmount } from './getAmount';

export function sumEitherLiquidityAmounts(
  amounts: MaybeEither<LiquidityAmount>[],
): Either<LiquidityAmount> {
  return combine(...amounts.map(a => toEither(a))).map(sumLiquidityAmounts);
}

export function sumPartialLiquidityAmounts(
  meAmounts: MaybeEither<LiquidityAmount>[],
): LiquidityAmount {
  const amounts = meAmounts.map(meAmount =>
    toEither(meAmount).fold(
      a => a,
      () => getAmount(0, '$'),
    ),
  );

  return sumLiquidityAmounts(amounts);
}

export function sumLiquidityAmounts(amounts: LiquidityAmount[]): LiquidityAmount {
  return sumAmounts(amounts, getAmount(0, '$'));
}
