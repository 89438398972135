import * as R from 'ramda';

import { ProtocolRating, Rating } from 'domain/types';

import { schemas } from '../apostro-rest/types';

export function convertProtocolRatings(
  data: schemas['LendingRatingResponse'][],
): Map<number, ProtocolRating[]> {
  const ratings = R.groupBy<ProtocolRating>(
    rating => String(rating.protocolId),
    data.map(convertProtocolRating),
  );
  return new Map(Object.entries(ratings).map(([key, value]) => [parseInt(key, 10), value]));
}

export function convertProtocolRating(data: schemas['LendingRatingResponse']): ProtocolRating {
  const shared = {
    protocolId: data.lending_id,
    rating: data.rating as Rating,
  };

  switch (data.rating_type) {
    case 'WEIGHTED_AVERAGE':
      return {
        ...shared,
        ratingType: 'WEIGHTED_AVERAGE',
      };
    default:
      return {
        ...shared,
        ratingType: 'UNKNOWN',
      };
  }
}
