import * as R from 'ramda';

import { Handler } from 'api/utils/RestSDK';

import { Parameters, JsonResponses } from '../types';
import { BASE_URL, headers } from '../constants';

type URL = '/api/v1/ratings/lendings';

export const loadProtocolsRatings = Handler.new<Parameters<URL>>()
  .fetch(
    ({ timestamp }) => `${BASE_URL}/v1/ratings/lendings?timestamp=${Math.floor(timestamp / 1000)}`,
    () => ({ headers }),
  )
  .parseJson<JsonResponses<URL, 'get'>>()
  .memoizeWith((...args) => R.toString(args))
  .toFn();
