import * as R from 'ramda';

import { Handler } from 'api/utils/RestSDK';

import { Parameters, JsonResponses } from '../types';
import { BASE_URL, headers } from '../constants';

type URL = '/api/v1/xai-dashboard/metrics-history';

export const loadXaiMetricsHistory = Handler.new<Parameters<URL>>()
  .fetch(
    ({ from_timestamp, to_timestamp, frequency }) => {
      const params = [
        from_timestamp ? `from_timestamp=${Math.floor(from_timestamp / 1000)}` : null,
        to_timestamp ? `to_timestamp=${Math.floor(to_timestamp / 1000)}` : null,
        frequency ? `frequency=${frequency}` : null,
      ]
        .filter(Boolean)
        .join('&');
      return `${BASE_URL}/v1/xai-dashboard/metrics-history?${params}`;
    },
    () => ({ headers }),
  )
  .parseJson<JsonResponses<URL, 'get'>>()
  .memoizeWith((...args) => R.toString(args))
  .toFn();
