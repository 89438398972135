import { Price } from 'domain/types';
import { CIMap } from 'utils/js';

import { MarketPricesResponse } from '../apostro-rest/types';

export function convertMarketPrices(
  assets: string[],
  data: MarketPricesResponse,
): CIMap<string, Price | null> {
  return new CIMap(
    data.map((x, i) => {
      return [assets[i], x ? { address: assets[i], source: 'market', price: x.usd_price } : null];
    }),
  );
}
