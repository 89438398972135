/* eslint-disable no-constructor-return */
import * as R from 'ramda';
import { Observable, defer, merge, of } from 'rxjs';
import { distinctUntilChanged, filter, map, share } from 'rxjs/operators';

import { LONG_POLLING_TIMEOUT } from 'env';
import { memoize } from 'utils/decorators';
import { resetOnZeroRefs, timer } from 'utils/rxjs';

import { apiErrorInterceptor } from './apiErrorInterceptor';

export type Period = 'latest' | 'dayAgo' | 'weekAgo';

const ONE_SECOND = 1000;
const ONE_MINUTE = 60 * ONE_SECOND;
const ONE_HOUR = 60 * ONE_MINUTE;
const ONE_DAY = 24 * ONE_HOUR;
const ONE_WEEK = 7 * ONE_DAY;

/* eslint-disable class-methods-use-this */
class GlobalApi {
  constructor() {
    return apiErrorInterceptor.getProxiedObj(this);
  }

  @memoize((...args: string[]) => R.toString(args))
  public getDataTimestamp$(period: Period): Observable<number> {
    return merge(
      defer(() => of(this.getTime(period))),
      timer(0, ONE_SECOND).pipe(
        map(() => this.getTime(period)),
        filter(timestamp => timestamp % LONG_POLLING_TIMEOUT === 0),
      ),
    ).pipe(distinctUntilChanged(), share(resetOnZeroRefs()));
  }

  private getTime(period: Period): number {
    const now = Math.floor(Date.now() / ONE_MINUTE) * ONE_MINUTE;
    switch (period) {
      case 'latest':
        return now;
      case 'dayAgo':
        return now - ONE_DAY;
      case 'weekAgo':
        return now - ONE_WEEK;
    }
  }
}

export const globalApi = new GlobalApi();
