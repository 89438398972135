import * as R from 'ramda';

import { Handler } from 'api/utils/RestSDK';

import { Parameters, JsonResponses } from '../types';
import { BASE_URL, headers } from '../constants';

type URL = '/api/v1/xai-dashboard/positions-totals';

export const loadXaiPositionsTotals = Handler.new<Parameters<URL>>()
  .fetch(
    ({ timestamp }) => {
      const params = [timestamp ? `timestamp=${Math.floor(timestamp / 1000)}` : null]
        .filter(Boolean)
        .join('&');
      return `${BASE_URL}/v1/xai-dashboard/positions-totals?${params}`;
    },
    () => ({ headers }),
  )
  .parseJson<JsonResponses<URL, 'get'>>()
  .memoizeWith((...args) => R.toString(args))
  .toFn();
