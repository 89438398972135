import { Fraction, PercentAmount, Token } from '@akropolis-web/primitives';
import * as R from 'ramda';

import { XaiTotalMetrics, LendingInfo, XaiPositionsTotal, XaiInfo } from 'domain/types';
import { sumAmounts } from 'utils/amount';
import { getAmount } from 'domain/utils';
import { isEqualContracts } from 'utils/validators';

export function calculateXaiTotalMetrics(
  xaiToken: Token,
  totals: XaiPositionsTotal[],
  protocolInfo: LendingInfo,
): Pick<XaiInfo, 'marketsMetrics' | 'totalMetrics'> {
  const groupedByMarketVid = R.groupBy(({ marketVid }) => marketVid, totals);

  const marketsMetrics = Object.entries(groupedByMarketVid).map(([marketVid, marketTotals]) => {
    const minterTotals = marketTotals.filter(({ userType }) => userType === 'minter');

    const minterXaiDeposit = sumAmounts(R.pluck('xaiDeposit', minterTotals), getAmount(0, '$'));
    const allXaiDeposit = sumAmounts(R.pluck('xaiDeposit', marketTotals), getAmount(0, '$'));
    const allXaiDebt = sumAmounts(R.pluck('xaiDebt', marketTotals), getAmount(0, '$'));
    const otherXaiDeposit = allXaiDeposit.sub(minterXaiDeposit);

    const minterDepositRatio = allXaiDeposit.isZero()
      ? new Fraction(0)
      : minterXaiDeposit.div(allXaiDeposit).toFraction();

    const circulatingSupply = allXaiDebt.sub(otherXaiDeposit);
    const collaterals = marketTotals.map(({ token, collateral }) => ({
      token,
      collateral: collateral.mul(minterDepositRatio),
    }));
    const totalDebt = allXaiDebt.mul(minterDepositRatio);
    const creditLine = minterXaiDeposit;

    const collateralTokens =
      protocolInfo.markets
        .find(({ vid }) => vid === marketVid)
        ?.tokens.filter(token => !isEqualContracts(token, xaiToken)) || [];

    return {
      marketVid,
      collateralTokens,
      collaterals,
      totalDebt,
      creditLine,
      circulatingSupply,
    };
  });

  const circulatingSupply = sumAmounts(
    R.pluck('circulatingSupply', marketsMetrics),
    getAmount(0, '$'),
  );
  const creditLine = sumAmounts(R.pluck('creditLine', marketsMetrics), getAmount(0, '$'));
  const totalCollateral = sumAmounts(
    marketsMetrics.map(({ collaterals }) => R.pluck('collateral', collaterals)).flat(),
    getAmount(0, '$'),
  );
  const totalDebt = sumAmounts(R.pluck('totalDebt', marketsMetrics), getAmount(0, '$'));

  const totalMetrics: XaiTotalMetrics = {
    circulatingSupply,
    creditLine,
    totalCollateral,
    totalDebt,
    collateralRatio: totalDebt.isZero()
      ? new PercentAmount(0)
      : new PercentAmount(100).mul(totalCollateral).div(totalDebt),
  };

  return { totalMetrics, marketsMetrics };
}
