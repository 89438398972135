import { NetworkSlug } from 'domain/types';

export const ERROR_MESSAGES = {
  NOT_ALL_TOKENS_HAS_PRICE: 'No market data for some tokens',
  UNKNOWN_IMPACT_DATA: 'Could not calculate costs',
  UNKNOWN_RATING: 'Could not calculate rating',
  UNKNOWN_MARKET_PRICE: (symbol: string, network: NetworkSlug) =>
    `Market price not found for ${symbol} in ${network}`,
  UNKNOWN_MAX_LTV: 'Max LTV configuration not found',
  UNKNOWN_LIQUIDATION_TRESHOLD: 'Liquidation Threshold configuration not found',
  UNKNOWN_PROTOCOL_METRICS: 'Could not calculate complex metrics for this position',
  UNKNOWN_ORACLE_PRICE: (symbol: string, network: NetworkSlug) =>
    `Oracle price not found for ${symbol} in ${network}`,
} as const;
