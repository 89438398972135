export const blockExplorers: Record<string, string> = {
  default: 'etherscan.io',
  mainnet: 'etherscan.io',
  'arbitrum-one': 'arbiscan.io',
  polygon: 'polygonscan.com',
  avalanche: 'snowtrace.io',
  optimism: 'optimistic.etherscan.io',
  fantom: 'ftmscan.com',
  bsc: 'bscscan.com',
  harmony: 'explorer.harmony.one',
  moonbeam: 'moonbeam.moonscan.io',
  moonriver: 'moonriver.moonscan.io',
  gnosis: 'gnosisscan.io',
  base: 'basescan.org',
  aurora: 'explorer.aurora.dev',
  'zksync-era': 'explorer.zksync.io',
  manta: 'pacific-explorer.manta.network',
  linea: 'lineascan.build',
};
