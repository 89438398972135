import { LendingInfoBase, asNetworkSlug, asProductSlug, asVersionSlug } from 'domain/types';

import { schemas } from '../apostro-rest/types';

export function convertLendingInfo(data: schemas['LendingResponse']): LendingInfoBase {
  return {
    id: data.lending_id,
    fullName: data.lending_name,
    productSlug: asProductSlug(data.product_slug),
    versionSlug: asVersionSlug(data.version_slug),
    productName: data.product_name,
    network: asNetworkSlug(data.network_slug),
    networkName: data.network_name,
    isMultiMarket: data.markets_number > 1,
    marketsCount: data.markets_number,
  };
}
