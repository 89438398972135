import { FallbackedTotalUSD } from 'domain/types';
import { toEither } from 'utils/either';

export function isZero({
  value,
  fallback: { amountsWithPrice, amountsWithoutPrice },
}: FallbackedTotalUSD): boolean {
  const allAmounts = [...amountsWithPrice, ...amountsWithoutPrice];

  return toEither(value).fold(
    amount => amount.isZero(),
    () => allAmounts.length === 0 || allAmounts.every(a => a.isZero()),
  );
}
