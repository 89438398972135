import { PercentAmount, decimalsToWei } from '@akropolis-web/primitives';
import * as R from 'ramda';

import { getAmount } from 'domain/utils';
import { MarketRating, Rating } from 'domain/types';

import { schemas } from '../apostro-rest/types';

type MarketRatingResponse = Omit<schemas['MarketRatingResponse'], 'rating_type'> & {
  rating_type: schemas['RatingType'];
};

export function convertMarketRatings(data: MarketRatingResponse[]): Map<string, MarketRating[]> {
  const ratings = Object.entries(R.groupBy(x => x.marketVid, data.map(convertMarketRating)));
  return new Map(ratings);
}

export function convertMarketRating(data: MarketRatingResponse): MarketRating {
  const shareData = {
    protocolId: data.lending_id,
    marketVid: data.market_vid,
    rating: data.rating as Rating,
  };

  switch (data.rating_type) {
    case 'TOTAL':
      return {
        ...shareData,
        ratingType: data.rating_type,
        payload: null,
      };
    case 'BAD_DEBT':
      return {
        ...shareData,
        ratingType: data.rating_type,
        payload: {
          maxDebt: getAmount(data.payload.max_debt, '$').mul(decimalsToWei(18)),
          debtShare: new PercentAmount(data.payload?.debt_share).mul(100),
          badDebtsSum: getAmount(data.payload?.bad_debts_sum, '$').mul(decimalsToWei(18)),
          marketTvl: getAmount(data.payload.market_tvl, '$').mul(decimalsToWei(18)),
        },
      };
    case 'MANIPULATION_COMPLEXITY':
      return {
        ...shareData,
        ratingType: data.rating_type,
        payload: {
          dumpCost: data.payload.dump_cost
            ? getAmount(data.payload.dump_cost, '$').mul(decimalsToWei(18))
            : null,
          dumpRating: data.payload.dump_rating,
          dumpRiskTvl: data.payload.dump_risk_tvl
            ? getAmount(data.payload.dump_risk_tvl, '$').mul(decimalsToWei(18))
            : null,
          isStable: data.payload.is_stable,
          pumpCost: data.payload.pump_cost
            ? getAmount(data.payload.pump_cost, '$').mul(decimalsToWei(18))
            : null,
          pumpRating: data.payload.pump_rating,
          pumpRiskTvl: data.payload.pump_risk_tvl
            ? getAmount(data.payload.pump_risk_tvl, '$').mul(decimalsToWei(18))
            : null,
        },
      };
    default:
      return {
        ...shareData,
        ratingType: 'UNKNOWN',
        payload: null,
      };
  }
}
