import { NetworkSlug, ProductSlug, VersionSlug } from 'domain/types';

const tvls = [
  ['aave', 'v3', 'mainnet', 11160391470.966745],
  ['maker', 'default', 'mainnet', 6568583058.21845],
  ['spark', 'default', 'mainnet', 4607806590.3360815],
  ['aave', 'v2', 'mainnet', 3093834159.676705],
  ['venus', 'default', 'bsc', 2210720678.104416],
  ['compound', 'v3', 'mainnet', 2081872868.5688362],
  ['cream-finance', 'default', 'mainnet', 1898236103.0004592],
  ['compound', 'v2', 'mainnet', 1270841389.9101985],
  ['aave', 'v3', 'arbitrum-one', 874022036.0311878],
  ['aave', 'v3', 'polygon', 564009993.1461915],
  ['aave', 'v3', 'avalanche', 494073653.8818345],
  ['benqi-lend', 'default', 'avalanche', 439679707.4926554],
  ['fraxlend', 'default', 'mainnet', 383683049.4571011],
  ['morpho-blue', 'default', 'mainnet', 260631198.04835427],
  ['aave', 'v3', 'optimism', 258836485.36376154],
  ['radiant', 'v2', 'arbitrum-one', 243001819.11885414],
  ['rari-fuse', 'default', 'mainnet', 198452319.27312338],
  ['radiant', 'v2', 'bsc', 193851559.09683734],
  ['silo', 'llama', 'mainnet', 184829215.2634768],
  ['uwu-lend', 'default', 'mainnet', 169004438.1523288],
  ['iron-bank', 'default', 'mainnet', 165071675.81618795],
  ['aave', 'v2', 'polygon', 131171723.68614529],
  ['silo', 'default', 'arbitrum-one', 118119248.09178184],
  ['compound', 'v3', 'polygon', 86140727.81777777],
  ['aave', 'v3', 'gnosis', 68141623.96008146],
  ['compound', 'v3', 'arbitrum-one', 66671477.76296478],
  ['radiant', 'v2', 'mainnet', 65539956.60919849],
  ['spark', 'default', 'gnosis', 58117713.78197489],
  ['moonwell', 'default', 'moonbeam', 56636311.49319875],
  ['zerolend', 'default', 'manta', 56448892.77111898],
  ['sonne-finance', 'default', 'optimism', 56110062.273420796],
  ['unitus', 'default', 'arbitrum-one', 55641089.77532543],
  ['strike', 'default', 'mainnet', 55392786.21245178],
  ['aave', 'v2', 'avalanche', 47568671.825724736],
  ['zerolend', 'default', 'zksync-era', 45106132.25566725],
  ['aave', 'v3', 'bsc', 42633652.70007049],
  ['lodestar', 'v1', 'arbitrum-one', 41427853.90373601],
  ['scream', 'v1', 'fantom', 35008827.53971663],
  ['geist-finance', 'default', 'fantom', 27003050.225793317],
  ['silo', 'default', 'mainnet', 26424238.68537098],
  ['liqee', 'default', 'bsc', 20971552.263949256],
  ['flux-finance', 'default', 'mainnet', 20181962.66125032],
  ['trader-joe-lend', 'default', 'avalanche', 13305376.976819554],
  ['onyx-protocol', 'default', 'mainnet', 11309600.064674398],
  ['wepiggy', 'default', 'mainnet', 7614694.68633889],
  ['sturdy', 'default', 'mainnet', 6946911.506285515],
  ['moonwell', 'default', 'moonriver', 5388075.384345855],
  ['unitus', 'default', 'optimism', 4563864.6312901545],
  ['unitus', 'default', 'bsc', 4122569.620970212],
  ['unitus', 'default', 'mainnet', 3918485.378796251],
  ['aave', 'v3', 'harmony', 3764482.250140273],
  ['aurigami', 'default', 'aurora', 3380383.92328651],
  ['apeswap-lending', 'default', 'bsc', 2820891.040989805],
  ['fraxlend', 'default', 'arbitrum-one', 2047503.582968489],
  ['iron-bank', 'default', 'fantom', 1409235.8583866472],
  ['cream-finance', 'default', 'bsc', 1031795.5801412412],
  ['tender-finance', 'default', 'arbitrum-one', 968998.0369437429],
  ['wepiggy', 'default', 'arbitrum-one', 916341.6939037816],
  ['radiant', 'v1', 'arbitrum-one', 765166.231829048],
  ['iron-bank', 'default', 'avalanche', 690733.5162839303],
  ['wepiggy', 'default', 'optimism', 634101.0808057792],
  ['wepiggy', 'default', 'bsc', 406281.2215205816],
  ['aave', 'v3', 'fantom', 348034.7851464058],
  ['unitus', 'default', 'polygon', 336965.8983529697],
  ['rari-fuse', 'default', 'arbitrum-one', 305432.4943407163],
  ['iron-bank', 'default', 'optimism', 286393.79157801904],
  ['venus', 'default', 'mainnet', 184964.73784903274],
  ['wepiggy', 'default', 'harmony', 150298.99007927725],
  ['cream-finance', 'default', 'polygon', 131069.23799923935],
  ['lodestar', 'v0', 'arbitrum-one', 43872.250335948076],
  ['wepiggy', 'default', 'polygon', 38529.3049818817],
  ['wepiggy', 'default', 'moonriver', 29931.003870413515],
  ['wepiggy', 'default', 'moonbeam', 27991.912853621478],
  ['cream-finance', 'default', 'arbitrum-one', 11659.784637288658],
  ['wepiggy', 'default', 'aurora', 8611.0243859257],
  ['liqee', 'default', 'mainnet', 117.01693996742914],
] as unknown as [
  productSlug: ProductSlug,
  versionSlug: VersionSlug,
  networkSlug: NetworkSlug,
  tvl: number,
][];

type PRecord<K extends string, T> = Partial<Record<K, T>>;

const tvlByProduct = tvls.reduce<PRecord<ProductSlug, number>>(
  (acc, [productSlug, , , tvl]) => ({
    ...acc,
    [productSlug]: (acc[productSlug] || 0) + tvl,
  }),
  {},
);

const tvlByVersion = tvls.reduce<PRecord<ProductSlug, PRecord<VersionSlug, number>>>(
  (acc, [productSlug, versionSlug, , tvl]) => ({
    ...acc,
    [productSlug]: {
      ...acc[productSlug],
      [versionSlug]: (acc[productSlug]?.[versionSlug] || 0) + tvl,
    },
  }),
  {},
);

const tvlByNetwork = tvls.reduce<
  PRecord<ProductSlug, PRecord<VersionSlug, PRecord<NetworkSlug, number>>>
>(
  (acc, [productSlug, versionSlug, networkSlug, tvl]) => ({
    ...acc,
    [productSlug]: {
      ...acc[productSlug],
      [versionSlug]: {
        ...acc[productSlug]?.[versionSlug],
        [networkSlug]: (acc[productSlug]?.[versionSlug]?.[networkSlug] || 0) + tvl,
      },
    },
  }),
  {},
);

export function getProductTvl(productSlug: ProductSlug): number {
  return tvlByProduct[productSlug] || 0;
}

export function getVersionTvl(productSlug: ProductSlug, versionSlug: VersionSlug): number {
  return tvlByVersion[productSlug]?.[versionSlug] || 0;
}

export function getNetworkTvl(
  productSlug: ProductSlug,
  versionSlug: VersionSlug,
  networkSlug: NetworkSlug,
): number {
  return tvlByNetwork[productSlug]?.[versionSlug]?.[networkSlug] || 0;
}
