import * as R from 'ramda';
import { isEqualHex, toFraction } from '@akropolis-web/primitives';

import { Handler } from 'api/utils/RestSDK';
import { LONG_POLLING_TIMEOUT } from 'env';
import { calcSignificantValue } from 'domain/utils';

import { Parameters, JsonResponses, schemas } from '../types';
import { BASE_URL, headers } from '../constants';

type URL = '/api/v1/positions/history';

export const loadPositionHistory = Handler.new<Parameters<URL>>()
  .fetch(
    ({ account_vid, product_slug, version_slug, network_slug, market_vid, limit, offset }) =>
      `${BASE_URL}/v1/positions/history?${[
        `account_vid=${account_vid}`,
        `product_slug=${product_slug}`,
        `version_slug=${version_slug}`,
        `network_slug=${network_slug}`,
        `market_vid=${market_vid}`,
        limit ? `limit=${limit}` : null,
        offset ? `offset=${offset}` : null,
      ]
        .filter(Boolean)
        .join('&')}`,
    () => ({ headers }),
  )
  .parseJson<JsonResponses<URL, 'get'>>()
  .map(({ history, tokens, ...rest }) => ({
    history: history.filter(x => hasSignificantDiffs(x, tokens)),
    tokens,
    ...rest,
  }))
  .refetchByTimeout(LONG_POLLING_TIMEOUT)
  .memoizeWith((...args) => R.toString(args))
  .toFn();

function hasSignificantDiffs(
  { diffs }: schemas['PositionHistoryItem'],
  tokens: schemas['PositionHistoryResponse']['tokens'],
): boolean {
  return diffs.some(x => {
    const tokenDecimals = tokens.find(t => isEqualHex(t.address, x.token))?.decimals;
    if (!tokenDecimals) {
      return true;
    }
    const significantValue = calcSignificantValue(tokenDecimals);
    return (
      toFraction(x.deposit).abs().gt(significantValue) ||
      toFraction(x.debt).abs().gt(significantValue) ||
      toFraction(x.collateral).abs().gt(significantValue)
    );
  });
}
