import { TotalBalance } from 'domain/types';

import { calcSignificantValue } from './calcSignificantValue';

export function hasSignificantAmounts(amounts: TotalBalance): boolean {
  const { decimals } = amounts.totalDeposit.currency;
  const significantValue = calcSignificantValue(decimals);
  return (
    amounts.totalDebt.gt(significantValue) ||
    amounts.totalCollateral.gt(significantValue) ||
    amounts.totalDeposit.gt(significantValue)
  );
}
