import * as R from 'ramda';

import { BalanceUSD } from 'domain/types';
import { sumAmounts } from 'utils/amount';
import { combine, Either, toEither } from 'utils/either';

import { getAmount } from './getAmount';

export function sumEitherUsdBalances(amounts: Either<BalanceUSD>[]): Either<BalanceUSD> {
  return combine(...amounts).map(sumUsdBalances);
}

export function sumPartialUsdBalances(meAmounts: Either<BalanceUSD>[]): BalanceUSD {
  const amounts = meAmounts.map(meAmount =>
    toEither(meAmount).fold(
      a => a,
      () => ({
        collateral: getAmount(0, '$'),
        debt: getAmount(0, '$'),
        deposit: getAmount(0, '$'),
      }),
    ),
  );

  return sumUsdBalances(amounts);
}

export function sumUsdBalances(amounts: BalanceUSD[]): BalanceUSD {
  return {
    collateral: sumAmounts(R.pluck('collateral', amounts), getAmount(0, '$')),
    debt: sumAmounts(R.pluck('debt', amounts), getAmount(0, '$')),
    deposit: sumAmounts(R.pluck('deposit', amounts), getAmount(0, '$')),
  };
}
