import { Amount, LiquidityAmount, PercentAmount, TokenAmount } from '@akropolis-web/primitives';

import { BaseGrowth, Growth, GrowthPercent, GrowthUSD } from 'domain/types';

type GrowthByAmount<A> = A extends TokenAmount
  ? Growth
  : A extends LiquidityAmount
  ? GrowthUSD
  : A extends PercentAmount
  ? GrowthPercent
  : never;

export function calculateGrowth<A extends TokenAmount | LiquidityAmount | PercentAmount>(
  curr: A,
  prev: A | null | undefined,
  significantGrowth = 0,
): GrowthByAmount<A> | null {
  if (!prev || prev.isZero()) {
    return null;
  }

  const percent = new PercentAmount(curr.mul(100).div(prev).sub(100));
  const isSignificant = percent.abs().gte(significantGrowth);

  const growth: BaseGrowth<Amount> = {
    amount: isSignificant ? curr.sub(prev) : curr.withValue(0),
    percent: isSignificant ? percent : new PercentAmount(0),
    type: percent.gte(0) ? 'increase' : 'decrease',
  };

  return growth as GrowthByAmount<A>;
}
