import { Rating } from 'domain/types';
import { Either, left, right } from 'utils/either';

import { ERROR_MESSAGES } from './constants';
import { compareRatings } from './compareRatings';

export function getWorstRating<R extends { rating: Rating }>(allRatings: R[]): Either<R> {
  const worst = [...allRatings].sort((a, b) => compareRatings(b.rating, a.rating))[0];

  return worst ? right(worst) : left(ERROR_MESSAGES.UNKNOWN_RATING);
}
