import { Handler } from 'api/utils/RestSDK';

import { Parameters, JsonResponses } from '../types';
import { BASE_URL, headers } from '../constants';

type URL = '/api/v1/lendings';

export const loadLendings = Handler.new<Parameters<URL>>()
  .fetch(
    ({ product_slug, version_slug, network_slug, is_active }) => {
      const params = [
        typeof product_slug !== 'undefined' ? `product_slug=${product_slug}` : null,
        typeof version_slug !== 'undefined' ? `version_slug=${version_slug}` : null,
        typeof network_slug !== 'undefined' ? `network_slug=${network_slug}` : null,
        typeof is_active !== 'undefined' ? `is_active=${is_active}` : null,
      ]
        .filter(Boolean)
        .join('&');

      return `${BASE_URL}/v1/lendings?${params}`;
    },
    () => ({ headers }),
  )
  .parseJson<JsonResponses<URL, 'get'>>()
  .toFn();
