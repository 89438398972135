import { Amount, Fraction } from '@akropolis-web/primitives';

import { MAX_UINT_256 } from 'utils/bn';

import { toEither, type Either, type MaybeEither } from '../either';

const MINUS_INFINITY = MAX_UINT_256.muln(-1);

export function compareEitherAmounts(_a: MaybeEither<Amount>, _b: MaybeEither<Amount>) {
  const a = toEither(_a).fold(
    value => (value ? value.toFraction() : new Fraction(0)),
    () => new Fraction(MINUS_INFINITY),
  );
  const b = toEither(_b).fold(
    value => (value ? value.toFraction() : new Fraction(0)),
    () => new Fraction(MINUS_INFINITY),
  );

  if (a.eq(b)) {
    return 0;
  }

  return a.lt(b) ? 1 : -1;
}

export function makeEitherAmountsComparer<T>(selector: (value: T) => Either<Amount>) {
  return (a: T, b: T): 0 | 1 | -1 => compareEitherAmounts(selector(a), selector(b));
}
