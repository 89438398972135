import { SatisfiedStrict } from '_helpers';

export type PriceSource = 'market' | 'oracle';

export type Price = {
  price: number;
  source: PriceSource;
};

const providerTypes = [
  'UniswapV3',
  'BalancerV2',
  'ChainlinkV3',
  'FixedPrice',
  'LpToken',
  'Forwarded',
  'CustomFeedOnchain',
  'CustomFeedOffchain',
  'UniswapV3Forwarded',
  'Disabled',
  'Unknown',
] as const;

export type PriceProviderType = (typeof providerTypes)[number];

export function isPriceProviderType(value: string): value is PriceProviderType {
  return providerTypes.includes(value as PriceProviderType);
}

type ProviderTypeToPayload = SatisfiedStrict<
  Record<PriceProviderType, any>,
  {
    UniswapV3: string[]; // Uniswap pairs
    BalancerV2: string; // Balancer pool ID
    ChainlinkV3: string; // ChainLink feed contract
    FixedPrice: number; // Price in base currency
    LpToken: string[]; // Token addresses
    Forwarded: string; // Token address to get price from oracle
    CustomFeedOnchain: string; // Custom Onchain Feed address
    CustomFeedOffchain: string; // Custom Offchain Feed address
    UniswapV3Forwarded: [string, ...string[]]; // [Token addresses, ...Uniswap pairs]
    Disabled: null;
    Unknown: null;
  }
>;

export type PriceProvider = {
  tokenAddress: string;
} & {
  [key in PriceProviderType]: {
    type: key;
    payload: ProviderTypeToPayload[key];
  };
}[PriceProviderType];

export type PriceHistory = {
  price: number;
  timestamp: number;
}[];
