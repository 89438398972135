import * as R from 'ramda';

import { MarketInfo, asNetworkSlug, asProductSlug, asVersionSlug } from 'domain/types';
import { calcMarketNameFromTokens, sortMarketTokens } from 'domain/utils';

import { schemas } from '../apostro-rest/types';
import { convertToken } from './convertToken';

export function convertLendingMarket(
  data: schemas['LendingMarket'],
  isMultiMarketLending: boolean,
  lendingName: string,
): MarketInfo {
  const { market_vid, lending_id, tokens: rawTokens, name: marketName } = data;
  const tokens = R.uniqBy(t => t.address.toLowerCase(), rawTokens).map(convertToken);

  return {
    vid: market_vid,
    lendingId: lending_id,
    tokens: sortMarketTokens({
      productSlug: asProductSlug(data.product_slug),
      versionSlug: asVersionSlug(data.version_slug),
      network: asNetworkSlug(data.network_slug),
      marketTokens: tokens,
    }),
    marketName:
      marketName ||
      (isMultiMarketLending
        ? calcMarketNameFromTokens({
            productSlug: asProductSlug(data.product_slug),
            versionSlug: asVersionSlug(data.version_slug),
            network: asNetworkSlug(data.network_slug),
            marketTokens: tokens,
          })
        : lendingName),
  };
}
