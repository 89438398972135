import { PercentAmount, TokenAmount, zeroAddress } from '@akropolis-web/primitives';

import { normalizeAmount } from 'utils/amount';
import { Price, PriceHistory } from 'domain/types';
import { Either } from 'utils/either';

import { getAmount } from './getAmount';

export function toUsd(amount: TokenAmount, price: number) {
  return getAmount(normalizeAmount(amount, 18).mul(price), '$');
}

export function hasOraclePrice(assetTotals: { price: Either<Price> }[]): boolean {
  return assetTotals.some(x =>
    x.price.fold(
      p => p.source === 'oracle',
      () => false,
    ),
  );
}

export function hasUnknownPrice(assetTotals: { price: Either<Price> }[]): boolean {
  return assetTotals.some(x =>
    x.price.fold(
      () => false,
      () => true,
    ),
  );
}

export const oracleBaseAddressesBySymbol: Readonly<Record<string, string>> = {
  weth: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
  eth: zeroAddress,
};

export function getOracleBaseAddresses(oraclePrices: ({ base: string } | null)[]): string[] {
  const baseAddresses = oraclePrices.reduce((acc, curr) => {
    const address = curr && oracleBaseAddressesBySymbol[curr.base.toLowerCase()];
    return address && !acc.includes(address) ? acc.concat(address) : acc;
  }, [] as string[]);

  return baseAddresses;
}

export function findClosestByTimestamp(
  timestamp: number,
  sortedPriceHistory: PriceHistory,
): PriceHistory[number] | null {
  let i = 1;
  while (i < sortedPriceHistory.length) {
    if (sortedPriceHistory[i].timestamp > timestamp) {
      break;
    }
    i += 1;
  }
  return sortedPriceHistory[i - 1] || null;
}

export function isCriticalPriceDeviation(deviation: PercentAmount): boolean {
  return deviation.abs().gt(3);
}
