import { TotalBalanceUSD } from 'domain/types';
import { Either, combine, toEither } from 'utils/either';

import { getAmount } from './getAmount';

export function sumTotalUsdBalances(balances: TotalBalanceUSD[]): TotalBalanceUSD {
  return balances.reduce<TotalBalanceUSD>(
    (total, balance) =>
      balance
        ? {
            totalDeposit: total.totalDeposit.add(balance.totalDeposit),
            totalDebt: total.totalDebt.add(balance.totalDebt),
            totalCollateral: total.totalCollateral.add(balance.totalCollateral),
          }
        : total,
    {
      totalDeposit: getAmount(0, '$'),
      totalDebt: getAmount(0, '$'),
      totalCollateral: getAmount(0, '$'),
    },
  );
}

export function sumEitherTotalUsdBalances(
  amounts: Either<TotalBalanceUSD>[],
): Either<TotalBalanceUSD> {
  return combine(...amounts).map(sumTotalUsdBalances);
}

export function sumPartialTotalUsdBalances(balances: Either<TotalBalanceUSD>[]): TotalBalanceUSD {
  const amounts = balances.map(balance =>
    toEither(balance).fold(
      a => a,
      () => ({
        totalDeposit: getAmount(0, '$'),
        totalDebt: getAmount(0, '$'),
        totalCollateral: getAmount(0, '$'),
      }),
    ),
  );

  return sumTotalUsdBalances(amounts);
}
