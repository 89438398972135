import { Handler } from 'api/utils/RestSDK';

import { Parameters, JsonResponses } from '../types';
import { BASE_URL, headers } from '../constants';

type URL = '/api/v1/positions/account';

export const loadAccountPositions = Handler.new<Parameters<URL>>()
  .fetch(
    ({ account_vid, timestamp }) => {
      const params = [
        `account_vid=${account_vid}`,
        typeof timestamp !== 'undefined' ? `timestamp=${Math.floor(timestamp / 1000)}` : null,
      ]
        .filter(Boolean)
        .join('&');

      return `${BASE_URL}/v1/positions/account?${params}`;
    },
    () => ({ headers }),
  )
  .parseJson<JsonResponses<URL, 'get'>>()
  .toFn();
