import * as R from 'ramda';
import { decimalsToWei } from '@akropolis-web/primitives';

import { AssetRating, Rating } from 'domain/types';
import { CIMap } from 'utils/js';
import { getAmount } from 'domain/utils';

import { schemas } from '../apostro-rest/types';

export function convertAssetRatings(
  data: schemas['AssetRatingResponse'][],
): CIMap<string, AssetRating> {
  const ratings = R.groupBy<AssetRating>(
    rating => rating.tokenAddress,
    data.map(convertAssetRating),
  );
  return new CIMap(
    Object.entries(ratings).map(([key, assetRatings]) => [
      key,
      assetRatings.sort((a, b) => b.rating - a.rating)[0],
    ]),
  );
}

export function convertAssetRating(data: schemas['AssetRatingResponse']): AssetRating {
  const shared = {
    protocolId: data.lending_id,
    marketVid: data.market_vid,
    poolVid: data.pool_vid,
    rating: data.rating as Rating,
    tokenAddress: data.token_address,
  };

  switch (data.rating_type) {
    case 'MANIPULATION_COMPLEXITY':
      return {
        ...shared,
        ratingType: data.rating_type,
        payload: {
          dumpCost: data.payload.dump_cost
            ? getAmount(data.payload.dump_cost, '$').mul(decimalsToWei(18))
            : null,
          dumpRating: data.payload.dump_rating,
          dumpRiskTvl: data.payload.dump_risk_tvl
            ? getAmount(data.payload.dump_risk_tvl, '$').mul(decimalsToWei(18))
            : null,
          isStable: data.payload.is_stable,
          pumpCost: data.payload.pump_cost
            ? getAmount(data.payload.pump_cost, '$').mul(decimalsToWei(18))
            : null,
          pumpRating: data.payload.pump_rating,
          pumpRiskTvl: data.payload.pump_risk_tvl
            ? getAmount(data.payload.pump_risk_tvl, '$').mul(decimalsToWei(18))
            : null,
        },
      };
    default:
      return {
        ...shared,
        ratingType: 'UNKNOWN',
        payload: null,
      };
  }
}
