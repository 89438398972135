import { PriceProvider, isPriceProviderType } from 'domain/types';

import { PriceProvider as BackendPriceProvider } from '../apostro-rest/types';

export function convertOraclePriceProviders(
  dataSet: Array<BackendPriceProvider | null>,
): Array<PriceProvider | null> {
  return dataSet.map(data => {
    if (!data) {
      return null;
    }

    const { token, type, payload } = data;

    if (isPriceProviderType(type)) {
      return {
        tokenAddress: token,
        type,
        payload,
      } as PriceProvider;
    }

    return {
      tokenAddress: token,
      type: 'Unknown',
      payload: null,
    };
  });
}
