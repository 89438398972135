import { PositionHistoryItem } from 'domain/types';
import { getAmount } from 'domain/utils';
import { CIMap } from 'utils/js';

import { convertToken } from './convertToken';
import { schemas } from '../apostro-rest/types';

export function convertPositionHistory(
  data: schemas['PositionHistoryResponse'],
): PositionHistoryItem[] {
  const tokens = new CIMap(data.tokens.map(token => [token.address, convertToken(token)]));

  const historySet: PositionHistoryItem[] = data.history.map(item => ({
    date: new Date(item.timestamp * 1000),
    txHashes: item.txs,
    diffs: item.diffs.map(diff => {
      const token = tokens.get(diff.token);

      if (!token) {
        throw new Error(`convertPositionHistory: unknown token ${diff.token}`);
      }

      const deposit = getAmount(diff.deposit, token);
      const collateral = getAmount(diff.collateral, token);
      return {
        deposit,
        collateral,
        debt: getAmount(diff.debt, token),
        eventNames: diff.event_names,
        poolId: diff.pool_vid,
      };
    }),
  }));

  return historySet;
}
