import * as R from 'ramda';

import { Handler } from 'api/utils/RestSDK';

import { Parameters, JsonResponses } from '../types';
import { BASE_URL, headers } from '../constants';

type URL = '/api/v1/lendings/{product_slug}/{version_slug}/{network_slug}/assets/impact-costs';

export const loadImpactList = Handler.new<Parameters<URL>>()
  .fetch(
    ({ network_slug, product_slug, version_slug, timestamp }) =>
      `${BASE_URL}/v1/lendings/${product_slug}/${version_slug}/${network_slug}/assets/impact-costs?timestamp=${Math.floor(
        timestamp / 1000,
      )}`,
    () => ({ headers }),
  )
  .parseJson<JsonResponses<URL, 'get'>>()
  .memoizeWith((...args) => R.toString(args))
  .toFn();
