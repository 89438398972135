import { Fraction, PercentAmount, decimalsToWei } from '@akropolis-web/primitives';

import { LendingAssetTotal, Price, ProductSlug } from 'domain/types';
import { getAmount, toUsd } from 'domain/utils';
import { CIMap } from 'utils/js';
import { left, right } from 'utils/either';
import { ERROR_MESSAGES } from 'domain/utils/constants';

import { schemas } from '../apostro-rest/types';
import { convertToken } from './convertToken';

export function convertProtocolTotalValues(
  productSlug: ProductSlug,
  data: schemas['TotalBalancesResponse'],
  prices: CIMap<string, Price | null>,
): LendingAssetTotal[] {
  const baseMetrics: LendingAssetTotal[] = data.asset_totals.map(x => {
    const token = convertToken(x.token);

    const nullablePrice = prices.get(token.address);
    const price = nullablePrice
      ? right(nullablePrice)
      : left(ERROR_MESSAGES.UNKNOWN_MARKET_PRICE(token.symbol, token.network));

    const totalDeposit = getAmount(x.total_deposit, token);
    const totalDebt = getAmount(x.total_debt, token);
    const totalCollateral = getAmount(x.total_collateral, token);

    const supplyAPY = x.apr ? convertAPRtoAPY(x.apr.supply_apr) : null;
    const borrowAPY = x.apr ? convertAPRtoAPY(x.apr.borrow_apr) : null;

    const stableBorrowAPY =
      x.apr && x.apr.payload && 'StableBorrowAPR' in x.apr.payload
        ? convertAPRtoAPY(x.apr.payload.StableBorrowAPR)
        : null;
    const variableBorrowAPY =
      x.apr && x.apr.payload && 'VariableBorrowAPR' in x.apr.payload
        ? convertAPRtoAPY(x.apr.payload.VariableBorrowAPR)
        : null;

    return {
      productSlug,
      marketId: x.market_vid,
      token,
      price,
      balance: {
        totalDeposit,
        totalDebt,
        totalCollateral,
      },
      balanceUSD: price.map(p => ({
        totalDeposit: toUsd(totalDeposit, p.price),
        totalDebt: toUsd(totalDebt, p.price),
        totalCollateral: toUsd(totalCollateral, p.price),
      })),
      supplyAPY,
      borrowAPY,
      stableBorrowAPY,
      variableBorrowAPY,
    };
  });

  return baseMetrics;
}

function convertAPRtoAPY(inputAPR: string | number): PercentAmount {
  const apr = new Fraction(inputAPR).div(decimalsToWei(18)).toNumber();

  // ignore wrong aprs
  if (apr > 2100) {
    return new PercentAmount(0);
  }

  return getAmount((1 + apr / 365) ** 365 - 1, '%').mul(100);
}
