import {
  Value,
  Token,
  TokenAmount,
  LiquidityAmount,
  Currency,
  toFraction,
  PercentAmount,
} from '@akropolis-web/primitives';

export function getAmount(amount: Value, token: Token): TokenAmount;
export function getAmount(amount: Value, token: '$'): LiquidityAmount;
export function getAmount(amount: Value, token: '%'): PercentAmount;
export function getAmount(
  amount: Value,
  token: Token | '$' | '%',
): TokenAmount | LiquidityAmount | PercentAmount {
  if (token === '%') {
    return new PercentAmount(amount);
  }
  if (token === '$') {
    return new LiquidityAmount(toFraction(amount), new Currency('$', 18), {
      precisions: 2,
      symbolPosition: 'start',
    });
  }

  return new TokenAmount(toFraction(amount), token);
}
