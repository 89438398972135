export const riskFactorInfo =
  'Measures the risk of position liquidation, where 0 equals no risk and 1 equals liquidation';
export const impactCostInfo = 'Required capital to commit most feasible attack on the pool';
export const pumpCostInfo =
  'Required capital to manipulate the oracle price and commit an attack on the protocol, during which it will be possible to borrow assets in the protocol against inflated collateral';
export const dumpCostInfo =
  'Required capital to manipulate oracle price to trigger liquidations which can potentially lead to protocol’s losses';
export const collateralCompositionInfo =
  'What items of value (like different digital currencies or real-world assets) are being used to make a stablecoin keep its price.';
export const creditLinePerPool =
  'The maximum amount of a stablecoin that can be created (minted) using a specific type of digital currency.';
export const positionRating =
  'The position rating may differ from the market rating if the user has restricted the borrowing of his deposit.';
export const netExposureShare =
  'Net exposure measures the maximum possible amount of losses in a given market under unfavorable conditions. This is usually the difference between the total value of deposits and borrows.';
