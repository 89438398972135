import * as R from 'ramda';
import { O } from 'ts-toolbelt';

import { Handler } from 'api/utils/RestSDK';

import { Parameters, JsonResponses, LiqLevelsResponse } from '../types';
import { BASE_URL, headers } from '../constants';

type URL =
  '/api/v1/lendings/{product_slug}/{version_slug}/{network_slug}/markets/{market_vid}/liq-levels';

export const loadTokenLiqLevelsByMarket = Handler.new<Parameters<URL>>()
  .fetch(
    ({
      product_slug,
      version_slug,
      network_slug,
      market_vid,
      token_vid,
      liq_type,
      points_number,
    }) => {
      const params = [
        `token_vid=${token_vid}`,
        liq_type ? `liq_type=${liq_type}` : null,
        points_number ? `points_number=${points_number}` : null,
      ]
        .filter(Boolean)
        .join('&');

      return `${BASE_URL}/v1/lendings/${product_slug}/${version_slug}/${network_slug}/markets/${market_vid}/liq-levels?${params}`;
    },
    () => ({ headers }),
  )
  .parseJson<O.Merge<{ 200: LiqLevelsResponse }, JsonResponses<URL, 'get'>>>()
  .memoizeWith((...args) => R.toString(args))
  .map(data => ({
    ...data,
    current_price: parseFloat(data.current_price),
    liq_levels: data.liq_levels.map(ll => ({
      type: ll.type,
      from_price: parseFloat(ll.from_price),
      to_price: parseFloat(ll.to_price),
      market_liq_volume: parseFloat(ll.market_liq_volume),
      total_liq_volume: parseFloat(ll.total_liq_volume),
      market_liq_volume_usd: parseFloat(ll.market_liq_volume_usd),
      total_liq_volume_usd: parseFloat(ll.total_liq_volume_usd),
    })),
  }))
  .toFn();
