import { calcAvg, decimalsToWei, PercentAmount } from '@akropolis-web/primitives';
import * as R from 'ramda';

import {
  Position,
  PositionMetrics,
  PositionBalance,
  Price,
  BalanceUSD,
  asProductSlug,
  asVersionSlug,
  asNetworkSlug,
} from 'domain/types';
import { getAmount, sumEitherUsdBalances, sumPartialUsdBalances, toUsd } from 'domain/utils';
import { CIMap } from 'utils/js';
import { combine, Either, left, right } from 'utils/either';
import { ERROR_MESSAGES } from 'domain/utils/constants';

import { schemas } from '../apostro-rest/types';
import { convertToken } from './convertToken';

export function convertPosition(
  data: schemas['Position'],
  prices: CIMap<string, Price | null>,
): Position {
  const parts: PositionBalance[] = data.balances.map(x => {
    const token = convertToken(x.token);

    const nullablePrice = prices.get(x.token.address);
    const price: Either<Price> = nullablePrice
      ? right(nullablePrice)
      : left(ERROR_MESSAGES.UNKNOWN_MARKET_PRICE(token.symbol, token.network));
    const maxLtv = new PercentAmount(100).mul(x.max_ltv).div(decimalsToWei(18));
    const liquidationThreshold = new PercentAmount(100)
      .mul(x.liquidation_threshold)
      .div(decimalsToWei(18));

    const collateral = getAmount(x.collateral || 0, token);
    const debt = getAmount(x.debt || 0, token);
    const deposit = getAmount(x.deposit || 0, token);

    const isZeroBalance = collateral.isZero() && debt.isZero() && deposit.isZero();

    const part: PositionBalance = {
      token,
      poolId: x.pool_vid,
      price,
      maxLtv,
      liquidationThreshold,
      balance: {
        collateral,
        debt,
        deposit,
      },
      balanceUSD: isZeroBalance
        ? right({
            collateral: getAmount(0, '$'),
            debt: getAmount(0, '$'),
            deposit: getAmount(0, '$'),
          })
        : price.map(p => ({
            collateral: toUsd(collateral, p.price),
            debt: toUsd(debt, p.price),
            deposit: toUsd(deposit, p.price),
          })),
    };

    return part;
  });

  const usdBalances = R.pluck('balanceUSD', parts);
  const balanceUsd = sumEitherUsdBalances(usdBalances);
  const partialBalanceUSD = sumPartialUsdBalances(usdBalances);

  const position: Position = {
    balances: parts,
    metrics: calcMetrics(parts, balanceUsd),
    balanceUsd,
    partialBalanceUSD,
    account: data.account_vid,
    marketVid: data.market_vid,
    productSlug: asProductSlug(data.product_slug),
    versionSlug: asVersionSlug(data.version_slug),
    network: asNetworkSlug(data.network),
    lastActive: new Date(data.last_active_time * 1000),
  };

  return position;
}

function calcMetrics(
  balances: PositionBalance[],
  totalBalanceUsd: Either<BalanceUSD>,
): Either<PositionMetrics> {
  const ltv = totalBalanceUsd.map(({ collateral, debt }) =>
    collateral.isZero() ? new PercentAmount(0) : new PercentAmount(100).mul(debt).div(collateral),
  );

  const liquidationThreshold = combine(...balances.map(balance => balance.balanceUSD)).map(
    usdBalances =>
      new PercentAmount(
        calcAvg(
          ...usdBalances.map(({ collateral }, index) => ({
            value: balances[index].liquidationThreshold,
            weight: collateral,
          })),
        ),
      ),
  );
  const maxLtv = combine(...balances.map(balance => balance.balanceUSD)).map(
    usdBalances =>
      new PercentAmount(
        calcAvg(
          ...usdBalances.map(({ collateral }, index) => ({
            value: balances[index].maxLtv,
            weight: collateral,
          })),
        ),
      ),
  );

  const riskFactor = combine(liquidationThreshold, ltv).map(([ltPercent, ltvPercent]) =>
    ltPercent.isZero() ? 0 : ltvPercent.div(ltPercent).toNumber(),
  );

  const borrowLimit = combine(totalBalanceUsd, maxLtv).map(([amountsUsd, maxLtvPercent]) =>
    amountsUsd.deposit.mul(maxLtvPercent).div(100),
  );

  const positionMetrics: Either<PositionMetrics> = combine(
    ltv,
    liquidationThreshold,
    riskFactor,
    borrowLimit,
  ).map(([mLtv, mLiquidationThreshold, mRiskFactor, mBorrowLimit]) => {
    return {
      ltv: mLtv,
      liquidationThreshold: mLiquidationThreshold,
      riskFactor: mRiskFactor,
      borrowLimit: mBorrowLimit,
    };
  });

  return positionMetrics;
}
