import { BehaviorSubject, defer, firstValueFrom, interval, Observable, of } from 'rxjs';
import { concatMap, filter, map, share, tap } from 'rxjs/operators';
import * as R from 'ramda';

import type { CacheOptions } from './types';

export class CollectionRequestCache<K extends string, V> {
  private refCounts: Map<K, number> = new Map();
  private cache$ = new BehaviorSubject<Map<K, V>>(new Map());

  get currentKeys(): K[] {
    return Array.from(this.refCounts.entries())
      .filter(([, value]) => value > 0)
      .map(([key]) => key);
  }

  constructor(private options: CacheOptions<K, V>) {
    this.autoUpdateCache$.subscribe();
  }

  public get$(keys: K[]): Observable<V[]> {
    if (keys.length === 0) {
      return of([]);
    }

    return defer(() => {
      const prevKeys = this.currentKeys;
      this.increaseRefCount(keys);
      const nextKeys = this.currentKeys;
      const newKeys = R.difference(nextKeys, prevKeys);
      this.updateCacheFor(newKeys);
      return this.cache$;
    }).pipe(
      filter(cache => keys.every(key => cache.has(key))),
      map(cache => keys.map(key => cache.get(key) as V)),
      share({
        resetOnRefCountZero: () =>
          of(true).pipe(
            tap(() => {
              this.decreaseRefCount(keys);
            }),
          ),
      }),
    );
  }

  private autoUpdateCache$ = interval(this.options.refreshTimeout).pipe(
    concatMap(() => this.updateCacheFor(this.currentKeys)),
  );

  private async updateCacheFor(keys: K[]): Promise<void> {
    if (keys.length === 0) {
      return;
    }
    const values = await firstValueFrom(this.options.fetchData$(keys));
    if (values.length !== keys.length) {
      throw new Error(
        'CollectionRequestCache: response must be of the same length as its parameters',
      );
    }

    const nextEntries = R.zip(keys, values);
    const cachedEntries = this.cache$.getValue().entries();

    const inCurrentKeys = ([key]: [K, V]) => this.currentKeys.includes(key);

    this.cache$.next(new Map([...cachedEntries, ...nextEntries].filter(inCurrentKeys)));
  }

  private increaseRefCount(keys: K[]): void {
    keys.forEach(key => {
      const refCount = this.refCounts.get(key) ?? 0;
      this.refCounts.set(key, refCount + 1);
    });
  }

  private decreaseRefCount(keys: K[]): void {
    keys.forEach(key => {
      const refCount = this.refCounts.get(key) ?? 1;
      this.refCounts.set(key, refCount - 1);
    });
  }
}
