import { O } from 'ts-toolbelt';

import { Handler } from 'api/utils/RestSDK';

import { Parameters, JsonResponses, MarketPriceHistoryResponse } from '../types';
import { BASE_URL, headers } from '../constants';

type URL = '/api/v1/market-prices/history';

export const loadMarketPriceHistory = Handler.new<Parameters<URL>>()
  .fetch(
    ({ network_slug, token_address, start_unix_time, end_unix_time }) => {
      const params = [
        `network_slug=${network_slug}`,
        `token_address=${token_address}`,
        `start_unix_time=${start_unix_time}`,
        `end_unix_time=${end_unix_time}`,
      ].join('&');
      return `${BASE_URL}/v1/market-prices/history?${params}`;
    },
    () => ({ headers }),
  )
  .parseJson<O.Merge<{ 200: MarketPriceHistoryResponse }, JsonResponses<URL, 'get'>>>({
    404: () => ({ points: [] }),
  })
  .toFn();
