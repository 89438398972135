/* eslint-disable no-constructor-return */
import { map, switchMap, share, shareReplay } from 'rxjs/operators';
import * as R from 'ramda';
import { combineLatest, from, Observable, of } from 'rxjs';
import { PercentAmount } from '@akropolis-web/primitives';

import {
  LendingMetrics,
  LendingInfo,
  LendingAssetTotal,
  MarketMetrics,
  LendingTokenMetrics,
  ProtocolImpacts,
  LendingMetadata,
  TokenMetadata,
  ProductSlug,
  VersionSlug,
  NetworkSlug,
  LendingInfoBase,
  MarketInfo,
  type LendingSlugs,
} from 'domain/types';
import {
  calculateMarketMetrics,
  calculateTokensMetrics,
  calculateProtocolMetrics,
  getAmount,
} from 'domain/utils';
import { memoize } from 'utils/decorators';
import { resetOnZeroRefs } from 'utils/rxjs';
import { components } from 'api/generated/openapi/protocols';
import { toEither } from 'utils/either';
import { IS_STAGE_ACTIVATED } from 'env';

import {
  convertProtocolTotalValues,
  convertLendingInfo,
  convertImpactData,
  convertLendingMarket,
} from './converters';
import { apiErrorInterceptor } from './apiErrorInterceptor';
import { priceApi } from './priceApi';
import { globalApi } from './globalApi';
import {
  loadLendings,
  loadImpactList,
  loadLendingMetadataBySlug,
  loadProtocolSafeTokens,
  loadProtocolTotals,
  loadLendingMarkets,
} from './apostro-rest/public';

/* eslint-disable class-methods-use-this */
class ProtocolsApi {
  constructor() {
    return apiErrorInterceptor.getProxiedObj(this);
  }

  public getLendings$(withMarkets: true, slugs?: Partial<LendingSlugs>): Observable<LendingInfo[]>;
  public getLendings$(
    withMarkets: false,
    slugs?: Partial<LendingSlugs>,
  ): Observable<LendingInfoBase[]>;
  public getLendings$(
    withMarkets: boolean,
    slugs?: Partial<LendingSlugs>,
  ): Observable<LendingInfo[] | LendingInfoBase[]>;
  @memoize((...args: any[]) => R.toString(args))
  public getLendings$(
    withMarkets: boolean,
    slugs: Partial<LendingSlugs> = {},
  ): Observable<LendingInfo[] | LendingInfoBase[]> {
    return combineLatest([
      this.loadLendings$(slugs),
      withMarkets ? this.loadLendingMarkets$(slugs) : of(null),
    ]).pipe(
      map(([rawLendings, rawMarkets]): LendingInfo[] | LendingInfoBase[] => {
        const lendings = rawLendings.map(convertLendingInfo);

        if (rawMarkets === null) {
          return lendings;
        }

        const lendingById = Object.fromEntries(lendings.map(l => [l.id, l]));
        const markets = rawMarkets?.map(market =>
          convertLendingMarket(
            market,
            lendingById[market.lending_id].isMultiMarket,
            lendingById[market.lending_id].fullName,
          ),
        );
        const marketsByLendingId = markets.reduce<Record<number, MarketInfo[]>>(
          (acc, cur) => ({ ...acc, [cur.lendingId]: (acc[cur.lendingId] || []).concat(cur) }),
          {},
        );

        return lendings.map<LendingInfo>(lending => ({
          ...lending,
          markets: marketsByLendingId[lending.id],
          marketByVid: Object.fromEntries(
            marketsByLendingId[lending.id].map(market => [market.vid, market]),
          ),
        }));
      }),
      shareReplay(1),
    );
  }

  @memoize((...args: any[]) => R.toString(args))
  private loadLendings$(slugs: Partial<LendingSlugs> = {}) {
    const { productSlug, versionSlug, network } = slugs;

    return from(
      loadLendings({
        network_slug: network,
        product_slug: productSlug,
        version_slug: versionSlug,
        is_active: !IS_STAGE_ACTIVATED,
      }),
    ).pipe(shareReplay(1));
  }

  @memoize((...args: any[]) => R.toString(args))
  private loadLendingMarkets$(slugs: Partial<LendingSlugs> = {}) {
    const { productSlug, versionSlug, network } = slugs;

    return from(
      loadLendingMarkets({
        network_slug: network,
        product_slug: productSlug,
        version_slug: versionSlug,
        is_lending_active: !IS_STAGE_ACTIVATED,
      }),
    ).pipe(shareReplay(1));
  }

  public getLendingInfo$(withMarkets: true, slugs: LendingSlugs): Observable<LendingInfo>;
  public getLendingInfo$(withMarkets: false, slugs: LendingSlugs): Observable<LendingInfoBase>;
  public getLendingInfo$(
    withMarkets: boolean,
    slugs: LendingSlugs,
  ): Observable<LendingInfoBase | LendingInfo>;
  @memoize((...args: any[]) => R.toString(args))
  public getLendingInfo$(
    withMarkets: boolean,
    slugs: LendingSlugs,
  ): Observable<LendingInfoBase | LendingInfo> {
    return this.getLendings$(withMarkets, slugs).pipe(
      map(lendings => {
        if (lendings.length === 0) {
          throw new Error('Lending not found'); // TODO: check all trycatch
        }
        return lendings[0];
      }),
    );
  }

  @memoize((...args: string[]) => R.toString(args))
  public getLendingMetadata$({
    productSlug,
    versionSlug,
    network,
  }: {
    productSlug: ProductSlug;
    versionSlug: VersionSlug;
    network: NetworkSlug;
  }): Observable<LendingMetadata | null> {
    return loadLendingMetadataBySlug({
      product_slug: productSlug,
      version_slug: versionSlug,
      network_slug: network,
    }).pipe(shareReplay(1));
  }

  @memoize((...args: string[]) => R.toString(args))
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public getTokenMetadata$(_params: {
    productSlug: ProductSlug;
    network: NetworkSlug;
    tokenSymbol: string;
  }): Observable<TokenMetadata | null> {
    return of<TokenMetadata>({
      description:
        `XAI is an over-collateralized stablecoin with a soft peg to the ` +
        `US Dollar controlled by SiloDAO (Silo.finance). Users can collateralize ` +
        `and borrow XAI via several Silo markets.`,
      href: 'https://silopedia.silo.finance/the-silo-protocol/protocol-design/usdxai',
      tags: [
        { type: 'text', title: 'Type of Peg', value: 'Soft · to fiat' },
        { type: 'text', title: 'Type of backing', value: 'crypto' },
      ],
    }).pipe(shareReplay(1));
  }

  // TODO: add override for "dayAgo" which ignore 404 and return Observable<LendingMetrics | null>
  @memoize((...args: any[]) => R.toString(args))
  public getProtocolMetrics$(
    period: 'latest' | 'dayAgo',
    { productSlug, versionSlug, network }: LendingSlugs,
  ): Observable<LendingMetrics> {
    return this.getProtocolBaseMetrics$(period, { productSlug, versionSlug, network }).pipe(
      map(({ baseMetrics, timestamp }) =>
        calculateProtocolMetrics(productSlug, baseMetrics, timestamp),
      ),
    );
  }

  @memoize((...args: any[]) => R.toString(args))
  public getMarketsMetrics$(
    period: 'latest' | 'dayAgo',
    slugs: LendingSlugs,
  ): Observable<MarketMetrics[]> {
    return combineLatest([
      this.getProtocolBaseMetrics$(period, slugs),
      this.getLendingInfo$(true, slugs),
    ]).pipe(
      map(([{ baseMetrics, timestamp }, protocolInfo]) => {
        const marketMetrics = calculateMarketMetrics(baseMetrics, timestamp);

        const marketIdsWithMetrics = marketMetrics.map(({ marketId }) => marketId);

        const emptyMarkets = protocolInfo.markets.filter(
          ({ vid: id }) => !marketIdsWithMetrics.includes(id),
        );
        const emptyMarketMetrics = emptyMarkets.map<MarketMetrics>(emptyMarket => {
          const partialBalanceUSD = {
            totalDeposit: getAmount(0, '$'),
            totalCollateral: getAmount(0, '$'),
            totalDebt: getAmount(0, '$'),
          };

          return {
            marketId: emptyMarket.vid,
            timestamp: marketMetrics[0]?.timestamp || Date.now(),
            balanceUSD: toEither(partialBalanceUSD),
            partialBalanceUSD,
            borrowRatio: toEither(new PercentAmount(0)),
            utilizationRatio: toEither(new PercentAmount(0)),
            assetTotals: [],
          };
        });

        return [...marketMetrics, ...emptyMarketMetrics];
      }),
    );
  }

  @memoize((...args: any[]) => R.toString(args))
  public getTokensMetrics$(
    period: 'latest' | 'dayAgo',
    slugs: LendingSlugs,
  ): Observable<LendingTokenMetrics[]> {
    return this.getProtocolBaseMetrics$(period, slugs).pipe(
      map(({ baseMetrics, timestamp }) => calculateTokensMetrics(baseMetrics, timestamp)),
    );
  }

  // TODO: add override for "dayAgo" which ignore 404 and return Observable<MarketMetrics | null>
  @memoize((...args: any[]) => R.toString(args))
  public getMarketMetrics$(
    marketVid: string,
    period: 'latest' | 'dayAgo',
    { productSlug, versionSlug, network }: LendingSlugs,
  ): Observable<MarketMetrics> {
    return this.getMarketsMetrics$(period, { productSlug, versionSlug, network }).pipe(
      map(metrics => {
        const result = metrics.find(x => x.marketId === marketVid);

        // TODO: check if day ago metrics not found
        if (!result) {
          throw new Error('Market metrics not found');
        }

        return result;
      }),
      share(resetOnZeroRefs()),
    );
  }

  @memoize((...args: string[]) => R.toString(args))
  public getMarketsCount$({
    productSlug,
    versionSlug,
    network,
  }: {
    productSlug: ProductSlug;
    versionSlug: VersionSlug;
    network: NetworkSlug;
  }): Observable<number> {
    return this.getProtocolTotalsBySlug$({
      productSlug,
      versionSlug,
      network,
      period: 'latest',
    }).pipe(map(({ asset_totals }) => R.uniqBy(x => x.market_vid, asset_totals).length));
  }

  @memoize((...args: any[]) => R.toString(args))
  private getProtocolBaseMetrics$(
    period: 'latest' | 'dayAgo',
    { productSlug, versionSlug, network }: LendingSlugs,
  ): Observable<{ baseMetrics: LendingAssetTotal[]; timestamp: number }> {
    return this.getProtocolTotalsBySlug$({ productSlug, versionSlug, network, period }).pipe(
      switchMap(response =>
        this.convertToBaseTokenMetrics$({ productSlug, versionSlug, network, response }),
      ),
      share(resetOnZeroRefs()),
    );
  }

  @memoize((...args: any[]) => R.toString(args))
  public getImpactCosts$(
    { productSlug, versionSlug, network }: LendingSlugs,
    marketVid?: string,
  ): Observable<ProtocolImpacts> {
    return globalApi.getDataTimestamp$('latest').pipe(
      switchMap(timestamp =>
        combineLatest([
          this.getLendingInfo$(true, { productSlug, versionSlug, network }),
          loadImpactList({
            product_slug: productSlug,
            version_slug: versionSlug,
            network_slug: network,
            timestamp,
          }),
          loadProtocolSafeTokens({
            params: {
              product_slug: productSlug,
              version_slug: versionSlug,
              network_slug: network,
            },
          }),
        ]),
      ),
      map(([protocolInfo, impactResponse, safeTokensResponse]) =>
        convertImpactData({
          impactResponse: impactResponse.filter(
            ({ market_vid }) => !marketVid || market_vid === marketVid,
          ),
          protocolInfo,
          safeTokensResponse,
        }),
      ),
      share(resetOnZeroRefs()),
    );
  }

  private convertToBaseTokenMetrics$({
    productSlug,
    versionSlug,
    network,
    response,
  }: {
    productSlug: ProductSlug;
    versionSlug: VersionSlug;
    network: NetworkSlug;
    response: components['schemas']['TotalBalancesResponse'];
  }) {
    return priceApi
      .getTokenPrices$({
        productSlug,
        versionSlug,
        network,
        assets: R.uniq(response.asset_totals.map(t => t.token.address)),
      })
      .pipe(
        map(prices => ({
          baseMetrics: convertProtocolTotalValues(productSlug, response, prices),
          timestamp: response.timestamp * 1000,
        })),
      );
  }

  // Base ProtocolsRestApi methods

  @memoize((...args: string[]) => R.toString(args))
  public getProtocolTotalsBySlug$({
    productSlug,
    versionSlug,
    network,
    period,
  }: {
    productSlug: ProductSlug;
    versionSlug: VersionSlug;
    network: NetworkSlug;
    period: 'latest' | 'dayAgo';
  }) {
    return globalApi.getDataTimestamp$(period).pipe(
      switchMap(timestamp =>
        loadProtocolTotals({
          product_slug: productSlug,
          version_slug: versionSlug,
          network_slug: network,
          timestamp,
        }),
      ),
      share(resetOnZeroRefs()),
    );
  }
}

export const protocolsApi = new ProtocolsApi();
