import { GrowthUSD, TotalBalance, TotalBalanceUSD } from 'domain/types';

import { calculateGrowth } from './calculateGrowth';

export const calculateTotalBalanceUsdGrowth = (
  metric: TotalBalanceUSD,
  metricDayAgo: TotalBalanceUSD | null | undefined,
  type: keyof TotalBalance,
  significantGrowth = 0,
): GrowthUSD | null => {
  return calculateGrowth(metric[type], metricDayAgo?.[type], significantGrowth);
};
