import { LendingInfo } from '../types';

export function getMarketName(protocolInfo: LendingInfo, marketVid: string): string {
  const market = protocolInfo.marketByVid[marketVid];

  if (!market) {
    throw new Error(`Can't find market with vid:${marketVid} in protocol:${protocolInfo.fullName}`);
  }

  return market.marketName;
}
