import { Handler } from 'api/utils/RestSDK';

import { Parameters, JsonResponses } from '../types';
import { BASE_URL, headers } from '../constants';

type URL = '/api/v1/lendings/{product_slug}/{version_slug}/{network_slug}/assets/total-balances';

export const loadProtocolTotals = Handler.new<Parameters<URL>>()
  .fetch(
    ({ product_slug, version_slug, timestamp, network_slug, market_vid }) => {
      const params = [
        timestamp ? `timestamp=${Math.floor(timestamp / 1000)}` : '',
        market_vid ? `market_vid=${market_vid}` : '',
      ]
        .filter(Boolean)
        .join('&');

      return `${BASE_URL}/v1/lendings/${product_slug}/${version_slug}/${network_slug}/assets/total-balances${
        params ? `?${params}` : ''
      }`;
    },
    () => ({ headers }),
  )
  .parseJson<JsonResponses<URL, 'get'>>()
  .toFn();
