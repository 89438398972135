import { PositionRating, Rating, asNetworkSlug, asProductSlug, asVersionSlug } from 'domain/types';

import { schemas } from '../apostro-rest/types';

export function convertAccountPositions(
  data: schemas['AccountPosition'][],
  accountVid: string,
): PositionRating[] {
  return data.map(item => convertAccountPosition(item, accountVid));
}

export function convertAccountPosition(
  data: schemas['AccountPosition'],
  userVid: string,
): PositionRating {
  return {
    productSlug: asProductSlug(data.product_slug),
    versionSlug: asVersionSlug(data.version_slug),
    network: asNetworkSlug(data.network_slug),
    marketVid: data.market_vid,
    accountVid: userVid,
    rating: Math.round(data.avg_rating) as Rating,
    originalRating: data.avg_rating,
    usdValueAtRisk: data.usd_value_at_risk,
    positionId: data.id,
  };
}
