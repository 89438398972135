import { Price } from 'domain/types';
import { oracleBaseAddressesBySymbol } from 'domain/utils';
import { CIMap } from 'utils/js';
import { IS_DEVELOPMENT } from 'env';

import { schemas } from '../apostro-rest/types';

export function convertOraclePrices({
  assets,
  data,
  oracleBasePrices,
  expiringTimestamp,
}: {
  assets: string[];
  data: (schemas['PricePoint'] | null)[];
  oracleBasePrices: CIMap<string, Price | null>;
  expiringTimestamp: number;
}): CIMap<string, Price | null> {
  return new CIMap(
    data.map((x, i) => {
      const base = x?.base.toLowerCase();
      const baseAddress = base && oracleBaseAddressesBySymbol[base];
      const basePrice =
        (base === 'usd' && 1) || (baseAddress && oracleBasePrices.get(baseAddress)?.price) || null;

      if (!basePrice && IS_DEVELOPMENT) {
        console.warn(`Unknown price for oracle base ${base}`);
      }
      const address = assets[i];
      return x && x.timestamp * 1000 > expiringTimestamp && basePrice
        ? [address, { address, source: 'oracle', price: x.price * basePrice }]
        : [address, null];
    }),
  );
}
