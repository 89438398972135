import { Token, decimalsToWei } from '@akropolis-web/primitives';

import { LendingInfo, XaiPositionsTotal } from 'domain/types';
import { getAmount } from 'domain/utils';

import { schemas } from '../apostro-rest/types';

export function convertXaiPositionsTotals(
  protocolInfo: LendingInfo,
  xaiTotals: schemas['XaiPositionTotal'][],
): XaiPositionsTotal[] {
  const tokenByVid: Record<string, Token> = Object.fromEntries(
    protocolInfo.markets
      .map(({ tokens }) => tokens)
      .flat()
      .map(token => [token.address.toLowerCase(), token]),
  );

  return xaiTotals.map(
    (value): XaiPositionsTotal => ({
      xaiDeposit: getAmount(value.xai_deposit, '$').mul(decimalsToWei(18)),
      collateral: getAmount(value.total_collateral, '$').mul(decimalsToWei(18)),
      xaiDebt: getAmount(value.xai_debt, '$').mul(decimalsToWei(18)),
      marketVid: value.market_vid,
      token: tokenByVid[value.token_vid.toLowerCase()],
      userType: value.account_type as XaiPositionsTotal['userType'],
    }),
  );
}
