import { isEqualHex, Token, TokenAmount } from '@akropolis-web/primitives';
import * as R from 'ramda';

import {
  TotalBalance,
  LendingAssetTotal,
  MarketMetrics,
  LendingMetrics,
  LendingTokenMetrics,
  LendingInfo,
  ProductSlug,
  NetworkSlug,
  VersionSlug,
} from 'domain/types';
import { left } from 'utils/either';

import { getAmount } from './getAmount';
import { calculateBorrowRatio, calculateUtilizationRatio } from './calculateMetrics';
import { hasUnknownPrice } from './prices';
import { ERROR_MESSAGES } from './constants';
import { sumEitherTotalUsdBalances, sumPartialTotalUsdBalances } from './sumTotalUsdBalances';

export function getMarketTokens({
  protocolInfo,
  marketId,
}: {
  protocolInfo: LendingInfo;
  marketId: string;
}): Token[] {
  const marketInfo = protocolInfo.marketByVid[marketId];
  const tokens = R.uniqBy(token => token.address.toLowerCase(), marketInfo?.tokens || []);

  return sortMarketTokens({
    productSlug: protocolInfo.productSlug,
    versionSlug: protocolInfo.versionSlug,
    network: protocolInfo.network,
    marketTokens: tokens,
  });
}

export function calcMarketNameFromTokens({
  productSlug,
  versionSlug,
  network,
  marketTokens,
}: {
  productSlug: ProductSlug;
  versionSlug: VersionSlug;
  network: NetworkSlug;
  marketTokens: Token[];
}) {
  return R.pluck(
    'symbol',
    sortMarketTokens({ productSlug, versionSlug, network, marketTokens }),
  ).join('/');
}

export function sortMarketTokens({
  productSlug,
  versionSlug,
  network,
  marketTokens,
}: {
  productSlug: ProductSlug;
  versionSlug: VersionSlug;
  network: NetworkSlug;
  marketTokens: Token[];
}) {
  const getTokenWeight = makeGetTokenWeight({ productSlug, versionSlug, network });
  return [...marketTokens].sort((a, b) => getTokenWeight(a.symbol) - getTokenWeight(b.symbol));
}

export function calculateMarketMetrics(
  assetTotals: LendingAssetTotal[],
  timestamp: number,
): MarketMetrics[] {
  return R.pipe(
    R.groupBy<LendingAssetTotal, string>(x => x.marketId),
    R.mapObjIndexed((marketAssetTotals, marketId) => {
      const assetBalances = R.pluck('balanceUSD', marketAssetTotals);
      const eitherBalanceUSD = sumEitherTotalUsdBalances(assetBalances);
      const partialBalanceUSD = sumPartialTotalUsdBalances(assetBalances);

      return {
        marketId,
        timestamp,
        balanceUSD: eitherBalanceUSD,
        partialBalanceUSD,
        assetTotals: marketAssetTotals,
        borrowRatio: hasUnknownPrice(marketAssetTotals)
          ? left(ERROR_MESSAGES.NOT_ALL_TOKENS_HAS_PRICE)
          : eitherBalanceUSD.map(balanceUSD => calculateBorrowRatio(balanceUSD)),
        utilizationRatio: hasUnknownPrice(marketAssetTotals)
          ? left(ERROR_MESSAGES.NOT_ALL_TOKENS_HAS_PRICE)
          : eitherBalanceUSD.map(balanceUSD => calculateUtilizationRatio(balanceUSD)),
      };
    }),
    R.values,
  )(assetTotals);
}

export function calculateTokensMetrics(
  assetTotals: LendingAssetTotal[],
  timestamp: number,
): LendingTokenMetrics[] {
  return R.pipe(
    R.groupBy<LendingAssetTotal, string>(x => x.token.address.toLowerCase()),
    R.mapObjIndexed(tokenAssetTotals => {
      const { token, price } = tokenAssetTotals[0];
      const balance = sumTotalBalances(token, R.pluck('balance', tokenAssetTotals));
      const balanceUSD = sumEitherTotalUsdBalances(R.pluck('balanceUSD', tokenAssetTotals));

      return {
        timestamp,
        token,
        price: price.map(p => p),
        balance,
        balanceUSD,
        utilizationRatio: calculateUtilizationRatio(balance),
        assetTotals: tokenAssetTotals,
      };
    }),
    R.values,
  )(assetTotals);
}

export function calculateProtocolMetrics(
  productSlug: ProductSlug,
  assetTotals: LendingAssetTotal[],
  timestamp: number,
): LendingMetrics {
  const assetBalances = R.pluck('balanceUSD', assetTotals);
  const eitherBalanceUSD = sumEitherTotalUsdBalances(assetBalances);
  const partialBalanceUSD = sumPartialTotalUsdBalances(assetBalances);

  const borrowRatio = eitherBalanceUSD.map(calculateBorrowRatio);
  const utilizationRatio = eitherBalanceUSD.map(calculateUtilizationRatio);

  return {
    productSlug,
    timestamp,
    balanceUSD: eitherBalanceUSD,
    partialBalanceUSD,
    borrowRatio,
    utilizationRatio,
    assetTotals,
  };
}

export function makeGetTokenWeight({
  productSlug,
  versionSlug,
  network,
}: {
  productSlug: ProductSlug;
  versionSlug: VersionSlug;
  network: NetworkSlug;
}) {
  const tokenWeightsByProtocol: Record<
    string,
    Record<string, Record<string, Record<string, number>>>
  > = {
    silo: {
      default: {
        mainnet: { weth: 1, xai: 2 },
        'arbitrum-one': { weth: 1, usdc: 2 },
      },
      llama: {
        mainnet: { crvusd: 1 },
      },
    },
    maker: {
      default: {
        mainnet: { dai: 1 },
      },
    },
    fraxlend: {
      default: {
        mainnet: { frax: 1 },
        'arbitrum-one': { frax: 1 },
      },
    },
  };

  return (tokenSymbol: string): number => {
    return (
      tokenWeightsByProtocol[productSlug]?.[versionSlug]?.[network]?.[tokenSymbol.toLowerCase()] ||
      0
    );
  };
}

function sumTotalBalances(token: Token, totals: TotalBalance[]): TotalBalance {
  return totals.reduce(
    (acc: TotalBalance, curr) => ({
      totalDeposit: sumTheSameTokens(acc.totalDeposit, curr.totalDeposit),
      totalDebt: sumTheSameTokens(acc.totalDebt, curr.totalDebt),
      totalCollateral: sumTheSameTokens(acc.totalCollateral, curr.totalCollateral),
    }),
    {
      totalDeposit: getAmount(0, token),
      totalDebt: getAmount(0, token),
      totalCollateral: getAmount(0, token),
    },
  );
}

function sumTheSameTokens(a: TokenAmount, b: TokenAmount): TokenAmount {
  if (!isEqualHex(a.currency.address, b.currency.address)) {
    throw new Error('Tokens should be equal');
  }

  return a.add(b);
}
