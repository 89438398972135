import * as R from 'ramda';

import { Handler } from 'api/utils/RestSDK';
import { LONG_POLLING_TIMEOUT } from 'env';

import { Parameters, JsonResponses } from '../types';
import { BASE_URL, headers } from '../constants';

type URL = '/api/v1/positions';

type Args = Parameters<URL>;
type SharedParams = Pick<Args, 'is_archived' | 'limit' | 'offset' | 'timestamp'>;

type OptionalFilters = Pick<
  Args,
  'product_slug' | 'version_slug' | 'network_slug' | 'market_vid' | 'account_vid'
>;
type LendingPositionArgs = OptionalFilters &
  Required<Pick<Args, 'product_slug' | 'version_slug' | 'network_slug'>>;
type AccountPositionArgs = OptionalFilters & Required<Pick<Args, 'account_vid'>>;
type MarketPositionArgs = OptionalFilters &
  Required<Pick<Args, 'product_slug' | 'version_slug' | 'network_slug' | 'market_vid'>>;

export type PositionFilters = LendingPositionArgs | AccountPositionArgs | MarketPositionArgs;
export type PositionRequestArgs = PositionFilters & SharedParams;

export const loadPositions = Handler.new<PositionRequestArgs>()
  .fetch(
    (args: Args) => getUrl(args),
    () => ({ headers }),
  )
  .parseJson<JsonResponses<URL, 'get'>>()
  .refetchByTimeout(LONG_POLLING_TIMEOUT)
  .memoizeWith((...args) => R.toString(args))
  .toFn();

function getUrl({
  account_vid,
  product_slug,
  version_slug,
  network_slug,
  market_vid,
  limit,
  offset,
  is_archived,
  timestamp,
}: Args) {
  return `${BASE_URL}/v1/positions?${[
    product_slug ? `product_slug=${product_slug}` : null,
    version_slug ? `version_slug=${version_slug}` : null,
    network_slug ? `network_slug=${network_slug}` : null,
    market_vid ? `market_vid=${market_vid}` : null,
    account_vid ? `account_vid=${account_vid}` : null,
    typeof is_archived === 'boolean' ? `is_archived=${is_archived}` : null,
    limit ? `limit=${limit}` : null,
    offset ? `offset=${offset}` : null,
    timestamp ? `timestamp=${Math.floor(timestamp / 1000)}` : '',
  ]
    .filter(Boolean)
    .join('&')}`;
}
