import { O } from 'ts-toolbelt';
import * as R from 'ramda';

import { Handler } from 'api/utils/RestSDK';
import { LONG_POLLING_TIMEOUT } from 'env';

import { RequestBody, Parameters, JsonResponses, OraclePriceProvidersResponse } from '../types';
import { BASE_URL, headers } from '../constants';

type URL = '/api/v1/oracle/prices/providers';

export const loadOraclePriceProviders = Handler.new<{
  assets: RequestBody<URL>;
  params: Parameters<URL>;
}>()
  .fetch(
    ({ params }) =>
      `${BASE_URL}/v1/oracle/prices/providers?${new URLSearchParams(params).toString()}`,
    ({ assets }) => ({
      headers,
      method: 'POST',
      body: JSON.stringify(assets),
    }),
  )
  .parseJson<O.Merge<{ 200: OraclePriceProvidersResponse }, JsonResponses<URL, 'post'>>>({
    404: () => [],
  })
  .refetchByTimeout(LONG_POLLING_TIMEOUT)
  .memoizeWith((...args) => R.toString(args))
  .toFn();
