import { colors } from 'core/styles';
import { Rating } from 'domain/types';

export const ratingColors: Record<Rating | 'N/A', string> = {
  0: colors.greenShamrock,
  1: colors.richYellow,
  2: colors.lightTitian,
  3: colors.scarlet2,
  4: colors.red2,
  'N/A': colors.silver,
};

export const landingRatingColors: Record<Rating | 'N/A', string> = {
  0: '#0CDC72',
  1: '#99DC0C',
  2: '#EDBF1B',
  3: '#ED661B',
  4: '#F9461F',
  'N/A': '#141D39',
};

export const ratingNA = (protocol: string) => `No rating for the ${protocol}`;
