import { BalanceUSD, TotalBalanceUSD } from 'domain/types';
import { Either, isEither } from 'utils/either';

export function toTotalBalanceUSD(balance: BalanceUSD): TotalBalanceUSD;
export function toTotalBalanceUSD(balance: Either<BalanceUSD>): Either<TotalBalanceUSD>;
export function toTotalBalanceUSD(
  balance: BalanceUSD | Either<BalanceUSD>,
): TotalBalanceUSD | Either<TotalBalanceUSD> {
  return isEither(balance)
    ? balance.map(b => ({
        totalCollateral: b.collateral,
        totalDebt: b.debt,
        totalDeposit: b.deposit,
      }))
    : {
        totalCollateral: balance.collateral,
        totalDebt: balance.debt,
        totalDeposit: balance.deposit,
      };
}
