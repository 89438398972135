import { Amount } from '@akropolis-web/primitives';

import { normalizeAmount } from './normalizeAmount';

export function compareAmounts(a: Amount | null, b: Amount | null): 0 | 1 | -1 {
  if (a && b) {
    const normalizedB = b.withValue(normalizeAmount(b, a.currency.decimals));
    if (a.eq(normalizedB)) {
      return 0;
    }

    return a.lt(normalizedB) ? 1 : -1;
  }

  if (!a && b) {
    return 1;
  }

  if (a && !b) {
    return -1;
  }

  return 0;
}

export function makeAmountsComparer<T>(selector: (value: T) => Amount | null) {
  return (a: T, b: T): 0 | 1 | -1 => compareAmounts(selector(a), selector(b));
}
