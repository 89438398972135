import { PriceHistory } from 'domain/types';

import { MarketPriceHistoryResponse } from '../apostro-rest/types';

export function convertMarketPriceHistory(data: MarketPriceHistoryResponse): PriceHistory {
  return data.points
    .map(([timestamp, price]) => ({
      timestamp: timestamp * 1000,
      price,
    }))
    .sort((a, b) => a.timestamp - b.timestamp);
}
