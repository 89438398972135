/* eslint-disable max-classes-per-file */
import { EitherErrorPayloads } from '_either';

import { never } from '../types';

type EitherErrorBase<C, P> = {
  code: C;
  payload: P;
};

type EitherError = {
  [key in keyof EitherErrorPayloads]: EitherErrorBase<key, EitherErrorPayloads[key]>;
}[keyof EitherErrorPayloads];

export type MaybeEither<T> = T | Either<T>;

type IRightValue<V> = {
  right: V;
};

type ILeftValue = {
  left: EitherError | string;
};

export abstract class Either<V> {
  public abstract value: IRightValue<V> | ILeftValue;

  map<R>(fn: (value: V) => R): Either<R> {
    if (isLeft(this)) {
      return this;
    }

    if (isRight(this)) {
      return right(fn(this.value.right));
    }

    return never();
  }

  fold<R>(onRight: (right: V) => R, onLeft: (left: EitherError | string) => R): R {
    if (isRight(this)) {
      return onRight(this.value.right);
    }
    if (isLeft(this)) {
      return onLeft(this.value.left);
    }
    return never();
  }

  toUndefined(): V | undefined {
    return isRight(this) ? this.value.right : undefined;
  }

  toNullable(): V | null {
    return isRight(this) ? this.value.right : null;
  }

  onRight<R>(onRight: (right: V) => R): R | null {
    return this.fold(onRight, () => null);
  }
}

export function isEither<R>(x: R | Either<R>): x is Either<R> {
  return x instanceof Either;
}

export function isLeft(x: Either<unknown>): x is Left {
  return x instanceof Left;
}
export function isRight<V>(x: Either<V>): x is Right<V> {
  return x instanceof Right;
}

export class Right<V> extends Either<V> {
  public value: IRightValue<V>;
  constructor(value: V) {
    super();
    this.value = { right: value };
  }
}

export class Left extends Either<never> {
  public value: ILeftValue;
  constructor(value: EitherError | string) {
    super();
    this.value = { left: value };
  }
}

export function left(error: EitherError | string): Left {
  return new Left(error);
}
export function right<R>(data: R): Right<R> {
  return new Right(data);
}

export function toEither<R>(value: R | Either<R>): Either<R> {
  return isEither(value) ? value : right(value);
}

type CombineEithers<L extends Either<any>[]> = Either<{
  [key in keyof L]: InferRight<L[key]>;
}>;

type InferRight<R> = R extends Either<infer V> ? V : never;

export function combine<L extends Either<any>[]>(...list: L): CombineEithers<L> {
  switch (list.length) {
    case 0:
      return left('Array is empty') as unknown as CombineEithers<L>;
    case 1:
      return list[0].map(value => [value]) as CombineEithers<L>;
  }

  const firstFailure = list.find(isLeft);
  if (firstFailure) {
    return firstFailure;
  }

  return right(
    list.map(x => (isRight(x) ? x.value.right : never())),
  ) as unknown as CombineEithers<L>;
}
