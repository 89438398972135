import { PriceHistory } from 'domain/types';
import { oracleBaseAddressesBySymbol } from 'domain/utils';
import { CIMap } from 'utils/js';

import { OraclePriceHistoryResponse } from '../apostro-rest/types';

type PriceHistoryPoint = PriceHistory[number];
type OraclePriceHistoryPoint = OraclePriceHistoryResponse['points'][number];

export function convertOraclePriceHistory(
  { points }: OraclePriceHistoryResponse,
  oracleBasePricesByAddress: CIMap<string, PriceHistory>,
  from: Date,
  to: Date,
  timeInterval: number,
): PriceHistory {
  if (points.length === 0) {
    return [];
  }

  const normOracleHistory = normalizeOraclePriceHistory(points, from, to, timeInterval);
  const normBaseHistories = Object.fromEntries(
    Array.from(oracleBasePricesByAddress.entries()).map(([base, history]) => [
      base,
      normalizePriceHistory(history, from, to, timeInterval),
    ]),
  );

  const oracleHistoryTimestamps = Object.keys(normOracleHistory)
    .map(time => Number(time))
    .sort((a, b) => a - b);

  const history = oracleHistoryTimestamps
    .map(timestamp => {
      const [, oraclePrice, base] = normOracleHistory[timestamp];
      const baseAddress = oracleBaseAddressesBySymbol[base.toLowerCase()];
      const basePrice =
        base === 'usd' ? 1 : normBaseHistories[baseAddress]?.[timestamp]?.price || null;

      return basePrice
        ? {
            timestamp,
            price: oraclePrice * basePrice,
          }
        : null;
    })
    .filter((value): value is PriceHistoryPoint => !!value);

  return history;
}

function normalizeOraclePriceHistory(
  pointsRaw: OraclePriceHistoryPoint[],
  from: Date,
  to: Date,
  timeInterval: number,
): Record<number, OraclePriceHistoryPoint> {
  if (!pointsRaw.length) {
    return [];
  }

  const [, firstPrice, firstBase] = pointsRaw[0];
  const [, lastPrice, lastBase] = pointsRaw[pointsRaw.length - 1];

  const points: OraclePriceHistoryResponse['points'] = [
    [from.getTime(), firstPrice, firstBase],
    ...pointsRaw.map<OraclePriceHistoryPoint>(([timeUnix, price, base]) => [
      timeUnix * 1000,
      price,
      base,
    ]),
    [to.getTime(), lastPrice, lastBase],
  ];
  const sortedPoints = points.sort(([timeA], [timeB]) => timeA - timeB);

  const normalized = sortedPoints.reduce<OraclePriceHistoryPoint[]>((acc, cur, index) => {
    const next = points[index + 1];

    if (!next) {
      return [...acc, cur];
    }

    const [curTimestamp, curPrice, curBase] = cur;
    const [nextTimestamp, nextPrice, nextBase] = next;

    const additionalPoints: OraclePriceHistoryPoint[] = [];
    let addPointTimestamp = Math.ceil(curTimestamp / timeInterval) * timeInterval;

    while (addPointTimestamp < nextTimestamp) {
      const price =
        curBase !== nextBase
          ? curPrice
          : (curPrice * (addPointTimestamp - curTimestamp) +
              nextPrice * (nextTimestamp - addPointTimestamp)) /
            (nextTimestamp - curTimestamp);
      additionalPoints.push([addPointTimestamp, price, curBase]);

      addPointTimestamp += timeInterval;
    }

    return [...acc, cur, ...additionalPoints];
  }, []);

  return normalized.reduce<Record<number, OraclePriceHistoryPoint>>(
    (acc, cur) => ({ ...acc, [cur[0]]: cur }),
    {},
  );
}

function normalizePriceHistory(
  pointsRaw: PriceHistoryPoint[],
  from: Date,
  to: Date,
  timeInterval: number,
) {
  if (!pointsRaw.length) {
    return [];
  }

  const { price: firstPrice } = pointsRaw[0];
  const { price: lastPrice } = pointsRaw[pointsRaw.length - 1];

  const points: PriceHistory = [
    { timestamp: from.getTime(), price: firstPrice },
    ...pointsRaw,
    { timestamp: to.getTime(), price: lastPrice },
  ];
  const sortedPoints = points.sort(({ timestamp: timeA }, { timestamp: timeB }) => timeA - timeB);

  const normalized = sortedPoints.reduce<PriceHistoryPoint[]>((acc, cur, index) => {
    const next = points[index + 1];

    if (!next) {
      return [...acc, cur];
    }

    const { timestamp: curTimestamp, price: curPrice } = cur;
    const { timestamp: nextTimestamp, price: nextPrice } = next;

    const additionalPoints: PriceHistoryPoint[] = [];
    let addPointTimestamp = Math.ceil(curTimestamp / timeInterval) * timeInterval;

    while (addPointTimestamp < nextTimestamp) {
      const price =
        (curPrice * (addPointTimestamp - curTimestamp) +
          nextPrice * (nextTimestamp - addPointTimestamp)) /
        (nextTimestamp - curTimestamp);
      additionalPoints.push({ timestamp: addPointTimestamp, price });

      addPointTimestamp += timeInterval;
    }

    return [...acc, cur, ...additionalPoints];
  }, []);

  return normalized.reduce<Record<number, PriceHistoryPoint>>(
    (acc, cur) => ({ ...acc, [cur.timestamp]: cur }),
    {},
  );
}
