import * as R from 'ramda';

import { Handler } from 'api/utils/RestSDK';

import { Parameters, JsonResponses } from '../types';
import { BASE_URL, headers } from '../constants';

type URL = '/api/v1/lendings/{product_slug}/{version_slug}/{network_slug}/ratings/markets';

export const loadMarketsRatings = Handler.new<Parameters<URL>>()
  .fetch(
    ({ timestamp, network_slug, product_slug, version_slug, market_vid }) => {
      const params = [
        `timestamp=${Math.floor(timestamp / 1000)}`,
        market_vid ? `market_vid=${market_vid}` : null,
      ]
        .filter(Boolean)
        .join('&');
      return `${BASE_URL}/v1/lendings/${product_slug}/${version_slug}/${network_slug}/ratings/markets?${params}`;
    },
    () => ({ headers }),
  )
  .parseJson<JsonResponses<URL, 'get'>>()
  .memoizeWith((...args) => R.toString(args))
  .toFn();
