import { O } from 'ts-toolbelt';

import { Handler } from 'api/utils/RestSDK';

import { RequestBody, Parameters, MarketPricesResponse, JsonResponses } from '../types';
import { BASE_URL, headers } from '../constants';

type URL = '/api/v1/market-prices';

export const loadMarketPrice = Handler.new<{ addresses: RequestBody<URL> } & Parameters<URL>>()
  .fetch(
    ({ network_slug }) => `${BASE_URL}/v1/market-prices?network_slug=${network_slug}`,
    ({ addresses }) => ({
      headers,
      method: 'POST',
      body: JSON.stringify(addresses),
    }),
  )
  .parseJson<O.Merge<{ 200: MarketPricesResponse }, JsonResponses<URL, 'post'>>>({
    404: ({ addresses }) => addresses.map(() => null),
  })
  .toFn();
