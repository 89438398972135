import { Rating } from 'domain/types';
import { MaybeEither, toEither } from 'utils/either';

export function compareRatings(_a: MaybeEither<Rating>, _b: MaybeEither<Rating>) {
  const a = toEither(_a).fold(
    value => Number(value),
    () => 100,
  );
  const b = toEither(_b).fold(
    value => Number(value),
    () => 100,
  );
  return a - b;
}
