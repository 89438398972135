import {
  decimalsToWei,
  isEqualHex,
  min,
  toFraction,
  Token,
  Value,
} from '@akropolis-web/primitives';
import * as R from 'ramda';

import { ImpactCosts, ProtocolImpacts, LendingInfo } from 'domain/types';
import { getAmount } from 'domain/utils';
import { CIMap } from 'utils/js';

import { schemas } from '../apostro-rest/types';

export function convertImpactData({
  impactResponse,
  protocolInfo,
  safeTokensResponse,
}: {
  impactResponse: schemas['AssetImpactCost'][];
  protocolInfo: LendingInfo;
  safeTokensResponse: schemas['LendingSafeToken'][];
}): ProtocolImpacts {
  const numberToUsd = (value: Value) => getAmount(toFraction(value), '$').mul(decimalsToWei(18));
  const isSafeToken = (address: string) =>
    safeTokensResponse.some(t => isEqualHex(t.token_vid, address));

  const protocolAssets = new CIMap(
    protocolInfo.markets
      .map(m => m.tokens)
      .flat()
      .map(t => [t.address, t]),
  );

  const requiredTokensForMarketImpact: Partial<Record<string, Token[]>> = Object.fromEntries(
    protocolInfo.markets.map(m => [m.vid, m.tokens.filter(t => !isSafeToken(t.address))]),
  );

  const allImpactCosts: ImpactCosts[] = impactResponse
    .map(
      ({
        pump_cost,
        dump_cost,
        target_pump_price,
        target_dump_price,
        token_address,
        market_vid,
        impact_cost_type,
      }) => {
        const token = protocolAssets.get(token_address);

        if (!token || isSafeToken(token_address)) {
          return null;
        }

        const cost: ImpactCosts = {
          marketVid: market_vid,
          token,
          pumpCost: numberToUsd(toFraction(pump_cost)),
          targetPumpPrice: numberToUsd(target_pump_price),
          dumpCost: numberToUsd(toFraction(dump_cost)),
          targetDumpPrice: numberToUsd(target_dump_price),
          model: impact_cost_type,
        };

        return cost;
      },
    )
    .filter((x: ImpactCosts | null): x is ImpactCosts => !!x);

  const minCostByMarketVid = Object.fromEntries(
    Object.entries(R.groupBy(cost => cost.marketVid, allImpactCosts)).map<
      [string, ImpactCosts | undefined]
    >(([marketId, costs]) => {
      const requiredTokens = requiredTokensForMarketImpact[marketId] || [];

      if (!requiredTokens.length) {
        return [marketId, undefined];
      }

      const hasAllRequiredCosts = requiredTokens.every(token =>
        costs.some(cost => isEqualHex(token.address, cost.token.address)),
      );

      if (!hasAllRequiredCosts) {
        return [marketId, undefined];
      }

      const minCost = [...costs].sort((a, b) =>
        min(a.dumpCost, a.pumpCost).sub(min(b.dumpCost, b.pumpCost)).toNumber(),
      )[0];

      return [marketId, minCost];
    }),
  );

  return { all: allImpactCosts, byMarketVid: minCostByMarketVid };
}
