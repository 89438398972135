import { PercentAmount, max } from '@akropolis-web/primitives';

import { TotalBalance, TotalBalanceUSD } from 'domain/types';

export function calculateBorrowRatio(balance: TotalBalanceUSD): PercentAmount {
  const { totalCollateral, totalDebt } = balance;
  const borrowRatio = totalCollateral.isZero()
    ? new PercentAmount(0)
    : new PercentAmount(totalDebt.div(totalCollateral).mul(100));
  return borrowRatio;
}

export function calculateUtilizationRatio(balance: TotalBalanceUSD): PercentAmount;
export function calculateUtilizationRatio(balance: TotalBalance): PercentAmount;
export function calculateUtilizationRatio(balance: TotalBalance | TotalBalanceUSD): PercentAmount {
  const { totalDeposit, totalDebt } = balance;
  const totalSupply = max(totalDeposit, totalDebt);
  const borrowRatio = totalSupply.isZero()
    ? new PercentAmount(0)
    : new PercentAmount(totalDebt.div(totalSupply).mul(100));
  return borrowRatio;
}
