import { Handler } from 'api/utils/RestSDK';
import { LendingMetadata } from 'domain/types';

import { Parameters, JsonResponses, schemas } from '../types';
import { BASE_URL, headers } from '../constants';

type URL = '/api/v1/lendings/{product_slug}/{version_slug}/{network_slug}/metadata';

export const loadLendingMetadataBySlug = Handler.new<Parameters<URL>>()
  .fetch(
    ({ product_slug, version_slug, network_slug }) =>
      `${BASE_URL}/v1/lendings/${product_slug}/${version_slug}/${network_slug}/metadata`,
    () => ({ headers }),
  )
  .parseJson<JsonResponses<URL, 'get'>>()
  .map(({ meta }): LendingMetadata | null => {
    return convertLendingMetadata(meta);
  })
  .toFn();

function convertLendingMetadata(
  meta: schemas['LendingMetadataResponse']['meta'],
): LendingMetadata | null {
  if (!meta) {
    return null;
  }

  return {
    description: meta.description,
    href: meta.href,
    tags: meta.tags,
  };
}
