import * as R from 'ramda';
import { Token } from '@akropolis-web/primitives';

import { Balance } from 'domain/types';
import { sumAmounts } from 'utils/amount';

import { getAmount } from './getAmount';

export function sumTokenBalances(amounts: Balance[], token: Token): Balance {
  return {
    collateral: sumAmounts(R.pluck('collateral', amounts), getAmount(0, token)),
    debt: sumAmounts(R.pluck('debt', amounts), getAmount(0, token)),
    deposit: sumAmounts(R.pluck('deposit', amounts), getAmount(0, token)),
  };
}
